import { ArrowBackIcon, EmailIcon } from "assets";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ContactUs() {
  const navigate = useNavigate();
  return (
    <div className="xl:py-10 py-8 xl:px-12 px-10 h-[calc(100%_-_75px)]">
      <div className="h-full bg-[#FDFEFF] border border-[#E5E5E5] rounded-xl overflow-hidden px-8 py-7">
        <div class="flex justify-between items-center mb-3">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center gap-1 text-[#121212] text-sm font-semibold leading-[19px]"
          >
            <img
              src={ArrowBackIcon}
              alt="back-arrow"
            />
            Back
          </button>
        </div>
        <div className="w-full h-full max-w-[420px] mx-auto flex flex-col justify-center items-center gap-[30px]">
          <img src={EmailIcon} alt="email-icon" />
          <div className="flex flex-col justify-center items-center gap-4">
            <span className="text-2xl text-[#1f1f1f] font-medium leading-8">
              email your queries to:
            </span>
            <span className="text-[40px] text-[#121212] font-semibold leading-9">
              contact@dialr.ai
            </span>
            <p className="text-[#494949] text-sm font-light leading-[22px] text-center">
              We aim to reply to every email within 2-4 hours. Rest assured,
              your query will be answered.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
