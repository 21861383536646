import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

export default function TabsComponent({
  tabValue,
  setTabValue,
  tabItems,
  isFromJobDetail,
  tabBackgroundColor
}) {
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box
      sx={{
        maxWidth: "100%",
        bgcolor: "background.paper",
        borderBottom: "1px solid #e5e5e580",
      }}
    >
      <Tabs
        value={tabValue}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
        aria-label="scrollable prevent tabs example"
        className={`${isFromJobDetail && "!min-h-[28px]"}`}
      >
        {tabItems.map((tab) => {
          return (
            <Tab
              label={tab.label}
              className={`${
                isFromJobDetail &&
                "flex-1 !capitalize !pt-1 !pb-[6px] !min-h-[28px] !max-w-full !font-hankengrotesk"
              } ${tabBackgroundColor ? `!bg-[#${tabBackgroundColor}]` : ""}`}
            />
          );
        })}
      </Tabs>
    </Box>
  );
}
