import { endPoints } from "services/endPoints";
import axiosInstance from "utils/axiosInstance";

export const getJobs = async (searchQuery, status) => {
  const endpoint = `${endPoints.job.getJobs}?search=${encodeURIComponent(
    searchQuery
  )}&status=${status}`;
  return await axiosInstance.get(endpoint);
};

export const getJobById = async (jobId, data) => {
  const url = endPoints.job.getJob.replace(":jobId", jobId);

  const res = await axiosInstance.get(url, data);
  return res?.data?.result?.[0];
};

export const createJob = async (data) => {
  return await axiosInstance.post(endPoints.job.createJob, data);
};

export const updateJob = async (jobId, data) => {
  const url = endPoints.job.updateJob.replace(":jobId", jobId);

  return await axiosInstance.put(url, data);
};

export const updateJobStatus = async (jobId, data) => {
  const url = endPoints.job.updateJobStatus.replace(":jobId", jobId);

  return await axiosInstance.post(url, data);
}

export const getJobCandidates = async (
  jobId,
  searchQuery,
  limit,
  offset,
  category = ""
) => {
  const endpoint = `${endPoints.job.getCandidates}?search=${encodeURIComponent(
    searchQuery
  )}&limit=${limit}&offset=${offset}&category=${category === "call_not_executed" ? null : category === "all" ? "" : category}`;
  return await axiosInstance.get(endpoint.replace(":job_id", jobId));
};

export const getJobCalls = async (jobId, searchQuery, limit, offset) => {
  const endpoint = `${endPoints.job.getCalls}?search=${encodeURIComponent(
    searchQuery
  )}&limit=${limit}&offset=${offset}`;
  return await axiosInstance.get(endpoint.replace(":job_id", jobId));
};

export const createCandidates = async (jobId, data) => {
  return await axiosInstance.post(
    endPoints.job.createCandidates.replace(":job_id", jobId),
    data
  );
};

export const deleteCandidate = async (jobId, candidateId) => {
  const url = endPoints.job.deleteCandidate
    .replace(":job_id", jobId)
    .replace(":candidate_id", candidateId);

  return await axiosInstance.delete(url);
};

export const updateCandidate = async (jobId, candidateId, data) => {
  const url = endPoints.job.updateCandidate
    .replace(":job_id", jobId)
    .replace(":candidate_id", candidateId);

  return await axiosInstance.post(url, data);
};

export const executeAICall = async (jobId, candidateId) => {
  const url = endPoints.job.executeAICall
    .replace(":job_id", jobId)
    .replace(":candidate_id", candidateId);

  return await axiosInstance.post(url);
};

export const cancelSingleCall = async (jobId, candidateId) => {
  const url = endPoints.job.cancelSingleCall
    .replace(":job_id", jobId)
    .replace(":candidate_id", candidateId);

  return await axiosInstance.post(url);
};

export const cancelAllCalls = async (jobId) => {
  const url = endPoints.job.cancelAllCalls
    .replace(":job_id", jobId);

  return await axiosInstance.post(url);
};

export const dialAllCalls = async (jobId) => {
  const url = endPoints.job.dialAllCalls
    .replace(":job_id", jobId);

  return await axiosInstance.post(url);
};

export const updateCandidateNotes = async (jobId, candidateId, data) => {
  const url = endPoints.job.updateCandidateNotes
    .replace(":job_id", jobId)
    .replace(":candidate_id", candidateId);

  return await axiosInstance.post(url, data);
};