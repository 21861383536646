import { CompletedGreen } from "assets";
import InfoDialog from "dialogs/InfoDialog";
import React, { useState } from "react";
import { sendFeedback } from "services/Feedback";

export default function Feedback() {
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [feedbackValidationError, setFeedbackValidationError] = useState("")
  const [infoDialoag, setInfoDialoag] = useState(false);

  const validate = () => {
    if (!feedback) {
      setFeedbackValidationError("Feedback is required");
      return false
    }
    return true
  }
  const handleSendFeedback = async () => {
    if (validate()){
      setLoading(true);
      await sendFeedback({
        feedback: feedback
      });
      setLoading(false);
      setFeedback("");
      setInfoDialoag(true);
    }
  }

  return (
    <div className="py-4 sm:py-6 lg:py-8 xl:py-10 px-4 sm:px-6 lg:px-10 xl:px-12 h-[calc(100vh_-_75px)] overflow-y-auto">
      {infoDialoag && (
        <InfoDialog
          open={infoDialoag}
          setOpen={setInfoDialoag}
          infoDetail={{
            heading: `Thank you! your feedback has been submitted.`,
            innerContent: "",
          }}
          handleSubmit={() => {}}
          icon={CompletedGreen}
        />
      )}
      <div className="h-full bg-[#FDFEFF] border border-[#E5E5E5] rounded-xl overflow-auto scrollbar p-4 sm:p-6 lg:p-8">
        <h5 className="text-2xl leading-9 font-medium text-[#4A4A4A] mb-4">
          Feedback
        </h5>
        <div className="flex flex-col w-full">
          <div className="flex flex-col gap-1 mb-7">
            <h6 className="text-base text-[#1f1f1f] font-medium leading-[22px] -tracking-[0.16px]">
              What could we have done better?
            </h6>
            <p className="text-xs text-[#1f1f1f] font-light leading-5 -tracking-[0.13px]">
              We'd love to hear your honest feedback or suggestions on how we
              can improve our app and your experience. We read every answer.
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-xs font-normal text-[#1f1f1f] leading-5 -tracking-[0.13px]">
              Feedback
            </label>
            <textarea
              name="lookingJobTitles"
              rows="5"
              placeholder={`Ex "Your app is great but it would be better if we could hear the call live"  `}
              value={feedback || ""}
              onChange={(e) => {
                const value = e.target.value;
                setFeedback(value);
                setFeedbackValidationError(!value ? "Feedback is required" : "");
              }}
              className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-3 px-3 sm:py-4 sm:px-4 block w-full outline-none resize-none text-sm sm:text-base"
            />
            {feedbackValidationError && (
              <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A] ">
                {feedbackValidationError}
              </div>
            )}
          </div>
          <div className="w-full flex flex-col sm:flex-row justify-between items-center gap-3 mt-[52px]">
            <button
              className="btn bg-white border-custom-primary h-12 flex items-center border border-purple font-semibold py-4 px-8 disabled:opacity-75 rounded-lg text-sm sm:text-base leading-5 text-[#121212] w-full sm:w-auto"
              onClick={() => {
                setFeedback("");
                setFeedbackValidationError("")
              }}
            >
              Clear
            </button>
            <button
              className="btn bg-custom-primary text-white h-12 flex items-center font-semibold py-4 px-8 rounded-lg disabled:opacity-75 text-sm leading-5 w-full sm:w-auto"
              onClick={() => handleSendFeedback()}
              disabled={loading}
            >
              {loading ? "Sending..." : "Send"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
