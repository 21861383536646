import { forwardRef } from "react";
import { Dialog, DialogContent, Slide } from "@mui/material";
import { CrossIcon, UsersPrimaryIcon, BriefcasePrimaryIcon } from "assets";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JobType = (props) => {
  const { open, setOpen, handleSelectJobType } = props;

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            maxWidth: "656px",
            borderRadius: "16px",
          },
        }}
      >
        <DialogContent>
          <img
            src={CrossIcon}
            alt="crossIcon"
            className="ml-auto cursor-pointer"
            onClick={handleClose}
          />
          <div className="mb-12 mt-2 rounded-md max-w-4xl mx-auto font-hankengrotesk">
            <div className="font-semibold text-2xl text-center mt-1">
              Who do you want to call?
            </div>
            <div className="flex sm:flex-nowrap flex-wrap justify-center gap-8 mt-4 min-h-[280px] sm:h-[280px] h-full">
              <div
                className="flex flex-col items-center justify-center sm:min-w-[256px] min-w-[200px] h-full border-2 border-gray rounded-md hover:border-custom-gray cursor-pointer"
                onClick={() => handleSelectJobType("candidates")}
              >
                <img
                  src={UsersPrimaryIcon}
                  alt="SplitScreenReelType"
                  className="mt-3 mx-auto"
                />
                <div className="text-md font-semibold text-center mt-3 mx-auto">
                  Candidates
                </div>
              </div>
              <div
                className="flex flex-col items-center justify-center sm:min-w-[256px] min-w-[200px] h-full border-2 border-gray rounded-md opacity-50 pointer-events-none"
              >
                <img
                  src={BriefcasePrimaryIcon}
                  alt="SplitScreenReelType"
                  className="mt-3 mx-auto"
                />
                <div className="text-md font-semibold text-center mt-3 mx-auto">
                  Recruitment Companies
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default JobType;
