import React, { useState } from "react";
import { Fragment } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Navigation } from "swiper/modules";

import {
  RightChevronArrow,
  PrevArrow,
  NextArrow,
  SlideImg2,
  SlideImg3,
  SlideImg4,
} from "assets";

export default function HowItWork() {
  const [activeStep, setActiveTab] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState(null); // Store Swiper instance

  const steps = [
    "Upload Candidates",
    "AI Calling / Filtering",
    "Present Talent to Company",
  ];

  // Function to change step with fade animation
  const handleChangeStep = (newTab) => {
    setActiveTab(newTab);
    // Change the Swiper slide to match the selected step
    if (swiperInstance) {
      swiperInstance.slideToLoop(newTab); // Move to the respective slide (loop mode enabled)
    }
  };

  return (
    <Fragment>
      <div
        className={`xl:w-[calc(100%_-_560px)] lg:w-[calc(100%_-_540px)] md:w-[calc(100%_-_304px)] sm:w-[calc(100%_-_284px)] sm:mx-auto mx-0 lg:mb-10 md:mb-8 mb-6 xl:py-[26px] lg:py-5 py-3 xl:px-[53px] lg:px-7 px-4 border border-[#E5E5E5] rounded-[10px] flex items-center`}
      >
        <div className="w-full flex justify-center md:mx-0 mx-auto lg:gap-6 md:gap-4 gap-2 text-[#454545] text-sm font-semibold">
          {steps.map((step, index) => {
            return (
              <div
                key={index}
                className="flex items-center lg:gap-6 md:gap-4 gap-2"
              >
                <div
                  className="nav tab-wrap cursor-pointer flex flex-row justify-center items-center gap-2 xl:flex-nowrap flex-wrap"
                  onClick={() => handleChangeStep(index)}
                >
                  <span
                    className={`lg:w-8 w-6 lg:h-8 h-6 rounded-full bg-[#F2F6FE] flex justify-center items-center text-custom-primary lg:text-[17px] text-sm font-medium leading-[23px] ${
                      activeStep === index ? "text-white bg-custom-primary" : ""
                    }`}
                  >
                    {index + 1}
                  </span>
                  <span
                    className={`text-[#353535] xl:text-lg lg:text-base md:text-sm text-xs font-medium sm:leading-[22px] leading-4 -tracking-[0.18px] opacity-[0.34] xl:whitespace-nowrap whitespace-break-spaces text-center ${
                      activeStep === index
                        ? "text-custom-primary opacity-[1]"
                        : ""
                    }`}
                  >
                    {step}
                  </span>
                </div>
                {index !== steps.length - 1 && (
                  <div className="min-w-[18px] h-[18px] flex justify-center items-center">
                    <img src={RightChevronArrow} alt="right-chevron-arrow" />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <div className="relative xl:w-[calc(100%_-_280px)] lg:w-[calc(100%_-_270px)] md:w-[calc(100%_-_152px)] sm:w-[calc(100%_-_142px)] w-full sm:mx-auto mx-0 flex justify-center items-center">
        {/* Previous Button */}
        <div className="swiper-button-prev cursor-pointer absolute top-1/2 xl:-left-[32px] md:-left-[22px] -left-[17px] -translate-y-1/2 z-10 bg-[#2764d20d] border border-[#E5E5E5] rounded-full xl:w-16 lg:w-[54px] md:w-[44px] w-[34px] xl:h-16 lg:h-[54px] md:h-[44px] h-[34px] flex justify-center items-center hover:bg-[rgba(39,100,210,0.07)]">
          <img
            src={PrevArrow}
            alt="previous-arrow"
            className="xl:w-9 md:w-6 w-4 xl:h-9 md:h-6 h-4"
          />
        </div>

        <Swiper
          slidesPerView={1} // Show one and a half slides
          centeredSlides={true}
          spaceBetween={140}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
          scrollbar={{ draggable: true }}
          modules={[Autoplay, Navigation]}
          className="xl:w-[calc(100%_-_280px)] lg:w-[calc(100%_-_270px)] md:w-[calc(100%_-_152px)] sm:w-[calc(100%_-_142px)] w-full !flex justify-center items-center"
          onSwiper={(swiper) => {
            setSwiperInstance(swiper);
            swiper.autoplay.start();
          }} // Capture Swiper instance
          onSlideChange={(swiper) => {
            setActiveTab(swiper.realIndex);
            swiper.autoplay.start(); // Restart autoplay after slide change
          }}
          onTouchEnd={(swiper) => swiper.autoplay.start()} // Restart autoplay after any touch/swipe attempt
          touchRatio={0} // Disable swiping
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 1,
              spaceBetween: 80,
            },
            1440: {
              slidesPerView: 1,
              spaceBetween: 140,
            },
          }}
        >
          <SwiperSlide>
            <div className="w-full flex justify-center items-center">
              <img src={SlideImg2} alt="slider-image" className="w-full" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full flex justify-center items-center">
              <img src={SlideImg3} alt="slider-image" className="w-full" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-full flex justify-center items-center">
              <img src={SlideImg4} alt="slider-image" className="w-full" />
            </div>
          </SwiperSlide>
        </Swiper>

        {/* Next Button */}
        <div className="swiper-button-next cursor-pointer absolute top-1/2 xl:-right-[32px] md:-right-[22px] -right-[17px] -translate-y-1/2 z-10 bg-[#2764d20d] border border-[#E5E5E5] rounded-full xl:w-16 lg:w-[54px] md:w-[44px] w-[34px] xl:h-16 lg:h-[54px] md:h-[44px] h-[34px] flex justify-center items-center hover:bg-[rgba(39,100,210,0.07)]">
          <img
            src={NextArrow}
            alt="next-arrow"
            className="xl:w-9 md:w-6 w-4 xl:h-9 md:h-6 h-4"
          />
        </div>
      </div>
      <div className="w-full md:max-w-[676px] max-w-full flex flex-col justify-center items-center gap-2 md:mt-9 sm:mt-6 mt-5 mx-auto lg:mb-10 md:mb-5 mb-0">
        <h6 className="text-[#1F1F1F] md:text-2xl sm:text-xl text-lg font-medium md:leading-8 sm:leading-6 leading-5">
          {steps[activeStep]}
        </h6>
        <p className="text-[#353535] md:text-lg sm:text-base text-sm font-light md:leading-[26px] sm:leading-6 leading-5 md:mx-7 mx-0 text-center">
          {activeStep === 0 &&
            "You will upload a list of candidates you wish to contact as a CSV. Don't worry, we'll provide you a friendly template for you to fill out."}
          {activeStep === 1 &&
            "AI will automatically call and filter through the candidates for you."}
          {activeStep === 2 &&
            "We will present the top talent to the companies you want to work with."}
        </p>
      </div>
    </Fragment>
  );
}
