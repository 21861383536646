import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from "react";

export default function CandidateFilters({
  anchorEl,
  handleClose,
  appliedFilters,
  handleSaveFilter,
}) {
  const [category, setCategory] = useState(appliedFilters?.category);

  const handleCategorySelect = (event) => {
    const { value } = event.target;
    setCategory(value);
  };

  return (
    <Popover
      id="candidate-filter"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      classes={{
        paper: "p-4 flex flex-col gap-[64px] !shadow-[0px_6px_20px_0px_rgba(0, 0, 0, 0.10)] !rounded-lg"
      }}
    >
      <div className="flex flex-col gap-1">
        <label className="text-[#1F1F1F] text-[13px] font-normal leading-5 -tracking-[0.13px] ">
          Call Outcome
        </label>
        <Select
          value={category}
          onChange={handleCategorySelect}
          variant="outlined"
          sx={{
            "&.Mui-focused": {
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#e2e2e2",
              },
            },
            "&:hover": {
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#e2e2e2",
              },
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: "1px solid #e2e2e2",
              borderRadius: "6px",
            },
          }}
          className="w-full min-w-[148px] max-h-[36px] py-3 px-2 md:px-[14px] !text-[#121212] !text-[13px] !font-medium !leading-4 focus-visible:outline-none"
          classes={{
            select: "!p-0",
          }}
        >
          {[
            { id: "all", label: "All"},
            { id: "interested", label: "Interested" },
            { id: "not_interested", label: "Not interested" },
            { id: "strong_candidate", label: "Good fit" },
            { id: "not_good_fit", label: "Not good fit" },
            { id: "unanswered", label: "Unanswered" },
            { id: "call_not_executed", label: "Call not executed" },
          ].map((cat) => {
            return (
              <MenuItem
                value={cat.id}
                className="!text-[#121212] !text-[13px] !font-normal !leading-4"
              >{cat.label}</MenuItem>
            );
          })}
        </Select>
      </div>
      <div className="w-full flex justify-between items-center gap-3">
        <button
          className={`btn text-[13px] h-[32px] bg-white flex items-center border-custom-primary border-[1px] text-black border-purple font-semibold py-1 px-6 rounded-lg`}
          onClick={() => handleSaveFilter(null)}
        >
          Clear
        </button>
        <button
          className="btn text-[13px] h-[32px] flex items-center bg-custom-primary text-white font-semibold py-1 px-6 disabled:opacity-75 rounded-lg"
          onClick={() =>
            handleSaveFilter({
              category: category,
            })
          }
        >
          Save
        </button>
      </div>
    </Popover>
  );
}
