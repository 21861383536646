import {
  ContactUsIcon,
  CreditCardIcon,
  HamburgerIcon,
  LogoutIcon,
  UserIcon,
} from "assets";
import _ from "lodash";
import React, { useContext, useState } from "react";
import { Paths } from "routes";
import { useNavigate } from "react-router-dom";
import AuthContext from "contexts/authContext";
import MenuPopper from "component/MenuPopper";
import SubscriptionPlanDialog from "dialogs/subscriptionDialog";
import AddCallsDialog from "dialogs/addCallsDialog";
import { clearIntervalForGetUser } from "utils/AppInit";

export default function Navbar({ auth }) {
  const { changeAuth } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [subscriptionPopup, setSubscriptionPopup] = useState(false);
  const [addCallsDialog, setAddCallsDialog] = useState(false);

  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (menu) => {
    handleClose();
    if (menu === "my_account") {
      navigate(Paths.MyAccount);
    } else if (menu === "contact_us") {
      navigate(Paths.ContactUs);
    } else if (menu === "subscription") {
      setSubscriptionPopup(true);
    } else if (menu === "logout") {
      clearIntervalForGetUser();
      localStorage.removeItem("token");
      localStorage.removeItem("AuthContext");
      localStorage.removeItem("trialEndingDialogState");
      changeAuth({ isLoggedIn: false, userData: {} });
      window.location.href = Paths.Login;
    }
  };

  return (
    <header class="bg-white border border-[#e5e5e5] px-[48px] py-[16px]">
      <div class="flex justify-between items-center">
        <div className="flex items-start justify-center flex-col">
          <div className="flex gap-2">
            <span class="text-gray-500 text-[12px]">
              Available Calls:{" "}
              <span className="text-[#121212] text-[14px] leading-5 font-semibold">
                {_.get(auth, "userData.UserData.credits", 0)}
              </span>
              {_.get(auth, "userData.UserData.status", "") === "trial" ? (
                <small className="text-[#121212] text-[12px] leading-5 font-normal">
                  &nbsp;({_.get(auth, "userData.UserData.trialDays", 0)} trial
                  day
                  {_.get(auth, "userData.UserData.trialDays", 0) > 1
                    ? "s"
                    : ""}{" "}
                  left)
                </small>
              ) : (
                <></>
              )}
            </span>
          </div>
          {!(
            _.get(auth, "userData.Subscription[0].status", "") === "active" ||
            _.get(auth, "userData.Subscription[0].status", "") === "trialing"
          ) ? (
            <>
              <span
                class="text-[#2764D2] text-[12px] leading-[18px] font-medium w-full underline cursor-pointer"
                onClick={() => setSubscriptionPopup(true)}
              >
                {_.get(auth, "userData.UserData.trialTaken", false)
                  ? "Trial Expired, Please Upgrade"
                  : "Upgrade Plan"}
              </span>
            </>
          ) : (
            <span
              class="text-[#2764D2] text-[12px] leading-[18px] font-medium w-full underline cursor-pointer"
              onClick={() => setAddCallsDialog(true)}
            >
              Add more calls
            </span>
          )}
        </div>
        <img
          src={HamburgerIcon}
          alt="logo"
          className="w-6 h-6 cursor-pointer"
          onClick={handleClick}
        />
        {subscriptionPopup && (
          <SubscriptionPlanDialog
            open={subscriptionPopup}
            setOpen={setSubscriptionPopup}
          />
        )}
        {addCallsDialog && (
          <AddCallsDialog open={addCallsDialog} setOpen={setAddCallsDialog} />
        )}
        <MenuPopper
          open={Boolean(anchorEl)}
          anchorRef={anchorEl}
          handleClose={handleClose}
          menuItems={[
            {
              id: "my_account",
              label: "My account",
              icon: UserIcon,
            },
            {
              id: "contact_us",
              label: "Contact Us",
              icon: ContactUsIcon,
            },
            {
              id: "subscription",
              label:
                _.get(auth, "userData.Subscription[0].status", "") ===
                  "active" ||
                _.get(auth, "userData.Subscription[0].status", "") ===
                  "trialing"
                  ? "Manage Subscription"
                  : "Subscribe",
              icon: CreditCardIcon,
            },
            {
              id: "logout",
              label: "Logout",
              icon: LogoutIcon,
            },
          ]}
          handleMenuClick={(id) => handleMenuClick(id)}
        />
      </div>
    </header>
  );
}
