import { forwardRef, useEffect, useRef, useState } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { Dialog, DialogContent, DialogContentText, Slide } from "@mui/material";
import { CrossIcon } from "assets";
import { validateOtp } from "services/Auth";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EmailVerificationOtp = (props) => {
  const { open, setOpen, handleSubmit, loading = false, email, handleResend, callFrom } = props;

  const [loader, setLoader] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [isResendDisabled, setResendDisabled] = useState(false);
  const [isCaptchaVisible, setIsCaptchaVisible] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [captchaCode, setCaptchaCode] = useState("");
  const [errors, setErrors] = useState({});
  const captchaRef = useRef(null);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      setResendDisabled(false);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  const handleResendClick = () => {
    if (callFrom === "signup" && !captchaCode) {
      setIsCaptchaVisible(true);
    } else {
      setCountdown(10);
      setResendDisabled(true);
      handleResend(captchaCode);
      setIsCaptchaVisible(false);
      setCaptchaCode("");
    }
  };

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  const validate = () => {
    const newErrors = {};
    if (!verificationCode) {
      newErrors.verificationCode = "Verification code is required";
    } else if (verificationCode.length !== 6) {
      newErrors.verificationCode = "Verification code must be exactly 6 characters";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        if (callFrom === "signup") {
          const data = await handleSubmit(verificationCode)
          data?.code && setErrors({ verificationCode: data?.title })
        } else {
          setLoader(true);
          const resp = await validateOtp({
            Email: email,
            Otp: verificationCode,
            Flag: ""
          });
          setLoader(false);
          if(resp.data.result === "OTP Valid") {
            handleSubmit(verificationCode)
          } else {
            setErrors({verificationCode: "Otp code not valid!"})
          }
        }
      } catch (err) {
        setLoader(false);
        setErrors({ verificationCode: err?.response?.data?.title })
      }
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;

    if (value.length <= 6) {
      setVerificationCode(value);

      if (value.length === 6) {
        setErrors((prevErrors) => ({ ...prevErrors, verificationCode: null }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          verificationCode: "Verification code must be exactly 6 characters",
        }));
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          style: {
            maxWidth: "544px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent className="!py-[52px] !px-[92px] relative">
          <DialogContentText id="alert-dialog-slide-description">
            <img
              src={CrossIcon}
              alt="crossIcon"
              className="ml-auto cursor-pointer absolute top-4 right-4"
              onClick={handleClose}
            />
            <div className="flex flex-col justify-start items-start gap-11 max-w-[360px] w-full mx-auto font-hankengrotesk">
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-2">
                  <h6 className="font-semibold text-[28px] leading-9 text-[#353535] m-0 ">
                    Verification Code
                  </h6>
                  <p className="text-[15px] text-[#1F1F1F] leading-[22px] -tracking-[1%] font-light m-0 ">
                    Please check your inbox (including spam folder), we sent you
                    an email with a 6-digit verification code.
                  </p>
                </div>

                <form
                  noValidate
                  onSubmit={handleFormSubmit}
                  className="flex flex-col"
                >
                  <div className="flex flex-col gap-1">
                    <label className="text-[13px] font-normal leading-5 -tracking-[0.15px] text-[#1F1F1F] ">
                      Verification Code
                    </label>
                    <input
                      name="verificationCode"
                      placeholder="Paste 6 digit code here"
                      onBlur={validate}
                      onChange={handleInputChange}
                      value={verificationCode}
                      maxLength="6"
                      className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-[14px] px-4 block w-full h-12 outline-none"
                    />
                    {errors.verificationCode && (
                      <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A] ">
                        {errors.verificationCode}
                      </div>
                    )}
                  </div>
                  {isCaptchaVisible && <div className="flex flex-col gap-1 mt-5">
                    <HCaptcha
                      ref={captchaRef}
                      sitekey="e0babe09-2202-4ea1-9882-55edfc867418"
                      onVerify={(token) => {
                        setCaptchaCode(token);
                      }}
                      onExpire={() => {
                        setCaptchaCode("");
                      }}
                      className="flex"
                    />
                    {!captchaCode && (
                      <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                        Please complete the Captcha to resend the OTP.
                      </div>
                    )}
                  </div>}
                </form>
              </div>
              <div className="w-full flex justify-between items-center gap-4">
                <button
                  type="button"
                  onClick={handleResendClick}
                  disabled={isResendDisabled || (callFrom === "signup" && isCaptchaVisible && !captchaCode)}
                  className="btn text-custom-primary disabled:opacity-75 disabled:cursor-not-allowed"
                >
                  {countdown > 0 ? `Resend in ${countdown}` : "Resend Code"}
                </button>
                <button
                  disabled={loading || loader}
                  type="button"
                  onClick={handleFormSubmit}
                  className="btn bg-custom-primary text-white font-semibold py-[14px] px-8 h-12 flex items-center rounded-lg disabled:opacity-75 "
                >
                  {loading || loader ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EmailVerificationOtp;
