import Skeleton from "@mui/material/Skeleton";
import React from "react";

export default function JobListSkeleton() {
  return (
    <div className="flex justify-between items-center border border-[#E5E5E5] rounded-md py-[45px] px-8">
      <Skeleton
        variant="rectangular"
        width={200}
        height={28}
        className="bg-[#F2F2F2] rounded "
      />
      <div className="flex items-center">
        <Skeleton
          variant="rectangular"
          width={124}
          height={28}
          className="bg-[#F2F2F2] rounded mr-6"
        />
        <div className="flex items-center gap-9">
          <Skeleton
            variant="rectangular"
            width={124}
            height={28}
            className="bg-[#F2F2F2] rounded"
          />
          <Skeleton
            variant="rectangular"
            width={48}
            height={28}
            className="bg-[#F2F2F2] rounded "
          />
        </div>
      </div>
      {/* ["","","",""].map((item) => {
        return (
        )
      }) */}
    </div>
  );
}
