import React from "react";
import { Slider, Box } from "@mui/material";

const RangeSlider = ({value, setValue}) => {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formatValue = (val) => {
    return `£${(val / 1000).toFixed(0)}K`;
  };

  return (
    <div className="flex flex-col gap-1 font-hankengrotesk">
      <label
            for="prompt"
            className="text-[15px] leading-5 font-normal -tracking-[1%] text-[#1f1f1f] mb-3"
          >
            What salary is the role you're hiring for offering? (Annual)
      </label>
      <div className="flex flex-col">
        <Box display="flex" justifyContent="space-between">
          <span color="textSecondary" className="text-[13px] text-[#1F1F1F] font-normal leading-5 -tracking-[1%] opacity-20">£20K</span>
          {/* <span className="text-[13px] text-[#1F1F1F] font-normal leading-5 -tracking-[1%]">{formatValue(value[0])}</span>
          <span className="text-[13px] text-[#1F1F1F] font-normal leading-5 -tracking-[1%]">{formatValue(value[1])}</span> */}
          <span color="textSecondary" className="text-[13px] text-[#1F1F1F] font-normal leading-5 -tracking-[1%] opacity-20">Max</span>
        </Box>
        <Slider
          value={value}
          valueLabelDisplay="on"
          onChange={handleChange}
          min={20000}
          max={200000}
          step={1000}
          valueLabelFormat={formatValue}
          sx={{
            color: "#2764D2", // Set the color of the slider
            height: 6, // Height of the slider track
            "& .MuiSlider-thumb": {
              border: "4px solid currentColor",
              height: 20,
              width: 20,
              backgroundColor: '#fff',
              boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
              '&:focus, &:hover, &.Mui-active': {
                boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                  boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)",
                },
              },
              '&:before': {
                boxShadow:
                  '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
              },
            },
            "& .MuiSlider-track": {
              height: 6,
            },
            "& .MuiSlider-rail": {
              height: 6,
              opacity: 0.5,
              backgroundColor: "#bfbfbf",
            },
            '& .MuiSlider-valueLabel': {
                fontSize: 13,
                fontWeight: '500',
                top: -6,
                backgroundColor: 'unset',
                fontFamily: "Hanken Grotesk",
                color: "#1F1F1F",
                '&::before': {
                  display: 'none',
                }
              }
            }}
        />
        
      </div> 
    </div>
  );
};

export default RangeSlider;
