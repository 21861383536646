import Skeleton from "@mui/material/Skeleton";
import { StyledTableCell, StyledTableRow } from "component/Table";
import React from "react";

export default function UnderReviewSkeleton({ columns, numberOfRows }) {
  return Array.from({ length: numberOfRows }).map(() => {
    return (
      <StyledTableRow>
        {columns?.map(() => {
          return (
            <StyledTableCell component="td" scope="row" className="p-2">
              <Skeleton
                variant="rectangular"
                width={"80%"}
                height={28}
                className="bg-[#F2F2F2] rounded mx-auto"
              />
            </StyledTableCell>
          );
        })}
      </StyledTableRow>
    );
  });
}
