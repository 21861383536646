import React from "react";
import {
  OverviewIcon,
  SmileyIcon,
  CrossIcon,
  HamburgerIcon,
  SettingIconGray,
} from "assets";
import { Avatar } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { Paths } from "routes";

export default function Sidebar({ auth, isSidebarOpen, setIsSidebarOpen }) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const navItems = [
    { name: "Overview", icon: OverviewIcon, path: "/" },
    // { name: "Candidate Database", icon: UsersIcon, path: "" },
    { name: "Feedback", icon: SmileyIcon, path: Paths.Feedback },
    // { name: "Analytics", icon: ReportAnalyticsIcon, path: "" },
    ...(auth?.userData?.isAdmin
      ? [
          {
            name: "Admin Access: Approve User",
            icon: SettingIconGray,
            path: Paths.AdminApproveUser,
          },
          {
            name: "Admin Access: Approve Trial",
            icon: SettingIconGray,
            path: Paths.AdminApproveTrial,
          },
        ]
      : []), // Only need to show this to admin user
  ];
  return (
    <>
      {/* Mobile toggle button */}
      <button
        className="md:hidden fixed top-4 left-4 z-20 p-2 bg-transparent border border-[#ffffff1a] rounded"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <img src={isSidebarOpen ? CrossIcon : HamburgerIcon} alt="menu" />
      </button>

      <aside
        className={`bg-[#061025] ${
          isSidebarOpen ? "block" : "hidden"
        }  text-white min-w-[232px] max-w-[232px] flex flex-col inset-y-0 left-0 z-10 transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out md:relative md:translate-x-0`}
      >
        <div class="px-5 py-4 border-b border-[#ffffff1a]">
          <div
            onClick={() => navigate("/my-account")}
            className="flex items-center gap-2"
          >
            <div className="w-10 h-10">
              <Avatar sx={{ bgcolor: "#384051", border: "2px solid #2764D2" }}>
                {auth.userData?.FirstName?.charAt(0) || ""}
              </Avatar>
            </div>
            <div class="flex flex-col ">
              <h6 className="text-xs font-light text-[#ffffffe6] leading-[14px] m-0">
                Welcome aboard
              </h6>
              <span className="text-sm font-semibold text-white leading-5 tracking-[0.4px]">
                {auth.userData?.FirstName || ""} {auth.userData?.LastName || ""}
              </span>
            </div>
          </div>
        </div>
        <nav class="p-5 h-full">
          <ul class="flex flex-col gap-2">
            {navItems.map((item) => {
              const isActive = currentPath === item.path;
              return (
                <li
                  className={`py-[14px] px-4 rounded-lg ${
                    isActive ? "bg-[#ffffff33]" : "bg-[#f6f9fd08]"
                  } hover:bg-[#ffffff33] cursor-pointer `}
                  onClick={() => {
                    navigate(item.path);
                  }}
                >
                  <div class="flex items-center text-sm font-medium leading-5 text-white gap-3">
                    <img src={item.icon} width={20} height={20} alt="Icon" />
                    <span>{item.name}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </nav>
        <p className="text-[24px] leading-8 font-semibold text-[#fff] text-center mb-[44px]">
          Dialr
        </p>
      </aside>
    </>
  );
}
