import { endPoints } from "services/endPoints";
import axiosInstance from "utils/axiosInstance";

export const createChat = async (jobId, message) => {
  return await axiosInstance.post(`${endPoints.AIChat.createChat.replace(":jobId", jobId)}`, {
    Message: message,
  });
};

export const getAllChat = async (jobId) => {
  return await axiosInstance.get(`${endPoints.AIChat.getAllChat.replace(":jobId", jobId)}`);
};

export const updateChatTitle = async (title, chatId) => {
  return await axiosInstance.put(`${endPoints.AIChat.updateChatTitle}`, {
    Title: title,
    ChatId: chatId,
  });
};

export const deleteChat = async (chatId) => {
  return await axiosInstance.delete(
    `${endPoints.AIChat.deleteChat.replace(":ChatId", chatId)}`
  );
};

export const getChatMessages = async (chatId) => {
  return await axiosInstance.get(
    `${endPoints.AIChat.getChatMessages.replace(":ChatId", chatId)}`
  );
};

export const addChatMessage = async (message, type, chatId) => {
  return await axiosInstance.put(`${endPoints.AIChat.addChatMessage}`, {
    Message: message,
    Type: type,
    ChatId: chatId,
  });
};
