import { CallInprogressIcon, ClockIcon, PhoneIcon } from "assets";
import _ from "lodash";

export const subscriptionPlans = [
  {
    price: "monthly1",
    planName: "1000 Calls / Month",
    planPrice: "£999",
    priceDetail: { duration: "month", saving: "0%", annualBill: "£11988" },
    includesFeatures: ["£0.99 per call", "AI Chat", "Centralized Database"],
  },
  {
    price: "monthly2",
    planName: "2000 Calls / Month",
    planPrice: "£1799",
    priceDetail: { duration: "month", saving: "11%", annualBill: "£21588" },
    includesFeatures: ["£0.89 per call", "AI Chat", "Centralized Database"],
    isMostPopularPlan: true,
  },
  {
    price: "monthly3",
    planName: "4000 Calls / Month",
    planPrice: "£2999",
    priceDetail: { duration: "month", saving: "36%", annualBill: "£35988" },
    includesFeatures: ["£0.74 per call", "AI Chat", "Centralized Database"],
  },
  {
    planName: "Unlimited Calls",
    planPrice: "",
    priceDetail: null,
    includesFeatures: ["AI Chat", "Centralized Database"],
    isCustomPrice: true,
  },
];

export const topUpPlans = [
  {
    price: "topup1",
    planName: "100 Calls",
    planPrice: "£110",
    priceDetail: {},
    includesFeatures: ["100 calls credit", "£1.10 per call"],
    buttonText: "Add 100 Calls",
  },
  {
    price: "topup2",
    planName: "200 Calls",
    planPrice: "£210",
    priceDetail: {},
    buttonText: "Add 200 Calls",
    includesFeatures: ["200 calls credit", "£1.05 per call"],
  },
  {
    price: "topup3",
    planName: "300 Calls",
    planPrice: "£310",
    priceDetail: {},
    buttonText: "Add 300 Calls",
    includesFeatures: ["300 calls credit", "£1.03 per call"],
  },
  {
    price: "topup4",
    planName: "400 Calls",
    planPrice: "£410",
    priceDetail: {},
    buttonText: "Add 400 Calls",
    includesFeatures: ["400 calls credit", "£1.03 per call"],
  },
];

export const mainNavbarItems = [
  { id: "how-it-works", label: "How it Works" },
  { id: "features", label: "Features" },
  { id: "faq", label: "FAQs" },
  { id: "pricing", label: "Pricing" },
  { id: "contact-us", label: "Contact Us" },
];

export function formatDate(dateStr) {
  // Create a Date object from the input date string
  const date = new Date(dateStr);

  // Get the user's local timezone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Format the date part (dd/mm/yyyy) according to the user's local timezone
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    timeZone: userTimeZone,
  });

  // Format the time part (h:mm am/pm) according to the user's local timezone
  const formattedTime = date
    .toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZone: userTimeZone,
    })
    .toLowerCase();

  // Combine and return the final formatted string
  return `${formattedDate} (${formattedTime} ${userTimeZone})`;
}

export const exportToCSV = (data) => {
  // Convert data to CSV string
  const headers = Object.keys(data[0]);
  const csvRows = [
    headers.join(","), // Header row
    ...data.map((row) =>
      headers
        .map(
          (fieldName) => JSON.stringify(row[fieldName] ?? "") // Use empty string for null/undefined values
        )
        .join(",")
    ),
  ];
  const csvString = csvRows.join("\n");

  // Create and download the file
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "candidates.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const handleDownloadSampleCsv = () => {
  const csvContent = [
    ["firstname", "lastname", "phonenumber"],
    ["John", "Doe", "+44 7123 456 789"],
    ["Jane", "Smith", "+44 7987 654 321"],
  ];

  const csvString = csvContent.map((row) => row.join(",")).join("\n");

  const blob = new Blob([csvString], { type: "text/csv" });

  // Create a temporary link to download the Blob
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", "template.csv");
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a); // Clean up the DOM
};

export function getCallDuration(startTime, endTime) {
  // Check if startTime or endTime is undefined
  if (!startTime || !endTime) {
    return "0s";
  }

  // Parse the start and end time as Date objects
  const start = new Date(startTime);
  const end = new Date(endTime);

  // Calculate the difference in milliseconds
  const durationMs = end - start;

  // Convert the difference to seconds
  const durationSeconds = Math.floor(durationMs / 1000);

  // Calculate minutes and remaining seconds
  const minutes = Math.floor(durationSeconds / 60);
  const seconds = durationSeconds % 60;

  // Format the duration as 'Xm Ys'
  if (minutes > 0) {
    return `${minutes}m ${seconds}s`;
  } else {
    return `${seconds}s`;
  }
}

export const debounce = (func, delay) => {
  return _.debounce(func, delay);
  // let timeoutId;
  // return function (...args) {
  //   if (timeoutId) {
  //     clearTimeout(timeoutId);
  //   }
  //   timeoutId = setTimeout(() => {
  //     func.apply(this, args);
  //   }, delay);
  // };
};

export const formatToK = (num) => {
  if (num >= 1000) {
    return "£" + (num / 1000).toFixed(1).replace(/\.0$/, "") + "k";
  } else {
    return "£" + num.toString();
  }
};

export const categoryChipColor = (category, callStatus) => {
  if ((callStatus === "in-progress" || callStatus === "ringing") && !category) {
    // return "text-[#176b20] bg-[#48BE5580]";
    return "text-[#1E9F1E] bg-[#E7F4E8]";
  } else if (!category && callStatus === "completed") {
    return "text-[#9933FF] bg-[#E5CCFF]";
  }

  switch (category) {
    case "interested":
    case "strong_candidate": {
      return "text-[#1E9F1E] bg-[#E7F4E8]";
    }
    case "not_interested": {
      return "text-[#F83A3A] bg-[#F83A3A1A]";
    }
    case "not_good_fit": {
      return "text-[#FF8512] bg-[#FF85121A]";
    }
    case "unanswered": {
      return "text-[#35353599] bg-[#F0F0F0]";
    }
    case "not_sure": {
      return "text-[#4B9CD3] bg-[#D3E6F9]";
    }
    default: {
      return "text-[#353535b3] bg-[#F2F6FD]";
    }
  }
};

export const callStatusDetail = (callStatus) => {
  let detail = {
    color: "bg-[#fff] text-[#353535] border-[#e5e5e5e] ",
    tooltip: "",
    icon: PhoneIcon,
    text: "Execute AI Call",
  };
  // eslint-disable-next-line default-case
  switch (callStatus) {
    case "in-progress":
    case "ringing": {
      detail = {
        color: "bg-[#48BE55] text-[#fff] border-[#48BE55]",
        tooltip:
          "This call is in progress, please wait 1-2 minutes. When call is complete you 'll be able to review the notes.",
        icon: CallInprogressIcon,
        text: "Call in Progress",
      };
      break;
    }
    case "queue":
    case "initiated":
    case "ready-to-call": {
      detail = {
        color: "bg-[#E7F4E8] text-[#353535] border-[#E7F4E8]",
        tooltip:
          "This call will start very soon. This can take upto 2-3 minutes.",
        icon: ClockIcon,
        text: "Call In Queue..",
      };
      break;
    }
    case "completed": {
      detail = {
        color: "bg-[#fff] text-[#353535] opacity-40",
        tooltip:
          "This call has already taken place. You can't call this candidate again with the AI agent. Please use their number to call them with your own services. ",
        icon: PhoneIcon,
        text: "Call Completed",
      };
      break;
    }
  }
  return detail;
};

export const goToChatMessageEnd = () => {
  const element = document.getElementById("list-bottom");
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

export const renderCategoryValue = (category, callStatus) => {
  return (callStatus === "in-progress" || callStatus === "ringing") && !category
    ? "In progress"
    : !category && callStatus === "completed"
    ? "Gathering Insights"
    : !category
    ? "Call not Executed"
    : category === "strong_candidate"
    ? "Good fit"
    : category === "not_sure"
    ? "unsure"
    : category?.split("_")?.join(" ");
};

export const resetUploadedFileValue = () => {
  const fileInput = document.getElementById("file-upload");
  if (fileInput) {
    fileInput.value = "";
  }
};
