import { forwardRef, useState } from "react";
import { Dialog, DialogContent, DialogContentText, Slide } from "@mui/material";
import { CrossIcon } from "assets";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CallInfo = ({ open, setOpen, handleSubmit, infoDetail }) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  const handleOkayClick = () => {
    if (dontShowAgain) {
      localStorage.setItem("hideCallInfoPopup", "true");
    }
    setOpen(false);
    handleSubmit();
  };

  const handleCheckboxChange = (e) => {
    setDontShowAgain(e.target.checked);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          style: {
            maxWidth: "520px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent className="!px-20 !py-[52px] relative">
          <DialogContentText id="alert-dialog-slide-description">
            <img
              src={CrossIcon}
              alt="crossIcon"
              className="ml-auto cursor-pointer absolute top-4 right-4"
              onClick={handleClose}
            />
            <div className="max-w-[360px] flex flex-col items-center font-hankengrotesk">
              <div className="w-full flex flex-col gap-[12px]">
                <div className="font-semibold text-[28px] leading-9 text-center text-[#353535]">
                  {infoDetail.heading}
                </div>
                {infoDetail?.innerContent && (
                  <div className="text-md text-center ">
                    {infoDetail?.innerContent}
                  </div>
                )}
              </div>
              <div className="mt-7 w-full">
                <FormControlLabel
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderTop: "1px solid #E5E5E5",
                      borderBottom: "1px solid #E5E5E5",
                      paddingTop: "14px",
                      paddingBottom:"14px",
                      margin: "0px",
                      width: "100%"
                    }}
                    control={
                      <Checkbox
                        disableRipple
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 28,
                            width: 20,
                            height: 20,
                            borderRadius: "4px", // Optional: Make the border corners rounded
                            border: "2px solid #E2E2E2", // Adding white border
                          },
                          color: "white", // Set the color of the checkbox to white
                        }}
                        checked={dontShowAgain}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Don’t show this message again"
                  />
              </div>
              <button
                className="btn h-12 flex justify-center items-center bg-custom-primary text-white font-medium py-[14px] text-center mt-9 rounded-lg w-full max-w-[120px]  disabled:opacity-75 flex-1 px-[32px] font-hankengrotesk"
                onClick={handleOkayClick}
              >
                Okay
              </button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CallInfo;
