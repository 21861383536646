import "./App.css";
import Router from "component/Router";
import { AuthProvider } from "contexts/authContext";
import RootContextWrapper from "contexts/rootContext";
import NotificationProvider from 'contexts/notificationContext';
import AppInit from "utils/AppInit";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme({
  typography: {
    fontFamily: `'Hanken Grotesk', sans-serif`,
  },
});


function App() {
  return (
    <div className="h-full">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <NotificationProvider>
            <RootContextWrapper>
              <Router />
              <AppInit />
            </RootContextWrapper>
          </NotificationProvider>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
