import React, { useState, useEffect } from "react";

function TypingLoader() {
  const [dots, setDots] = useState(".");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots.length < 5) {
          return prevDots + ".";
        }
        return ".";
      });
    }, 300); // Adjust interval for speed

    return () => clearInterval(interval); // Clean up on unmount
  }, []);

  return <div>{dots}</div>;
}

export default TypingLoader;
