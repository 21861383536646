import { useState, forwardRef, useEffect } from "react";
import { Dialog, DialogContent, Slide } from "@mui/material";
import { CompletedGreen, CrossIcon } from "assets";
import InfoDialog from "./InfoDialog";
import { updatePhoneNumber } from "services/Auth";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddPhoneNumber = ({ open, setOpen }) => {
  const [loading, setLoading] = useState(false);
  const [infoDialoag, setInfoDialoag] = useState(false);

  const [errors, setErrors] = useState({});

  const initialValues = {
    phone: "+44",
  };
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    if (!open) {
      setErrors({});
    }
  }, [open]);

  const handleClose = () => setOpen(false);

  const handleFieldChange = (fieldName, value) => {
    const newValues = { ...formValues, [fieldName]: value };
    setFormValues(newValues);

    // Real-time validation for changed field
    let newErrors = { ...errors };
    if (fieldName === "phone") {
      const formattedValue = value.replace(/\D/g, "");
      if (formattedValue.includes("44") && formattedValue.length === 2) {
        delete newErrors.phone;
      } else if (formattedValue.length !== 12) {
        // phone numbers have 44 + 10 digits
        newErrors.phone = "Please enter a valid UK phone number";
      } else {
        delete newErrors.phone;
      }
    }

    setErrors(newErrors);
  };

  const renderInputField = (name, placeholder, label, type = "text") => (
    <div className="flex flex-col gap-1">
      {renderLabel(label)}
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={formValues[name]}
        onBlur={() => handleFieldChange(name, formValues?.[name]?.trim())}
        onChange={(e) => {
          const inputValue = e.target.value;
          if (name === "phone") {
            // Ensure +44 prefix remains intact and allow only digits after +44
            const phoneValue = "+44" + inputValue.slice(3).replace(/\D/g, "");
            handleFieldChange(name, phoneValue);
          } else {
            handleFieldChange(name, inputValue);
          }
        }}
        className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none"
      />
      {errors[name] && (
        <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
          {errors[name]}
        </div>
      )}
    </div>
  );
  const renderLabel = (label) => (
    <label className="text-[#1F1F1F] text-[13px] font-normal leading-5 -tracking-[0.13px] ">
      {label}
    </label>
  );

  const validateForm = () => {
    let newErrors = {};
    const formattedValue = formValues.phone.replace(/\D/g, "");

    if (!formValues.phone) {
      newErrors.phone = `Phone number is required`;
    }
    if (formattedValue.length !== 12) {
      // phone numbers have 44 + 10 digits
      newErrors.phone = "Please enter a valid UK phone number";
    } else {
      delete newErrors.phone;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmitForm = async (e) => {
    debugger;
    e.preventDefault();
    if (validateForm()) {
      try {
        setLoading(true);
        await updatePhoneNumber({
          phone: formValues.phone,
        });
        setLoading(false);

        setInfoDialoag(true);
      } catch (err) {
        setLoading(false);
        if (err?.response?.data?.title) {
          setErrors({
            submit: err?.response?.data?.title,
          });
        }
      }
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        style: {
          maxWidth: "564px",
          borderRadius: "12px",
        },
      }}
    >
      <DialogContent className="!py-[52px] !px-[92px] relative">
        {infoDialoag && (
          <InfoDialog
            open={infoDialoag}
            setOpen={handleClose}
            infoDetail={{
              heading: `Phone number added successfully.`,
              innerContent: "",
            }}
            handleSubmit={handleClose}
            icon={CompletedGreen}
          />
        )}
        <img
          src={CrossIcon}
          alt="crossIcon"
          className="ml-auto cursor-pointer absolute top-4 right-4"
          onClick={handleClose}
        />
        <form
          onSubmit={handleSubmitForm}
          className="flex flex-col font-hankengrotesk"
        >
          <div className="text-[28px] leading-9 font-semibold text-left mb-5 text-[#353535]">
            Add Phone Number
          </div>
          <div className="flex flex-col gap-[18px]">
            {renderInputField(
              "phone",
              "Ex +44 7700 900000 (Optional)",
              "Phone Number (Optional)"
            )}
          </div>
          {errors?.submit && (
            <div className="w-full text-sm font-medium text-[#FF352A] mt-[22px] mb-3 ">
              {errors.submit}
            </div>
          )}
          <div className="w-full flex justify-between flex-row gap-3 sm:gap-0 mt-11">
            <button
              type="button"
              onClick={() => handleClose()}
              className={`btn bg-white border-custom-primary border-[1px] text-black border-purple font-semibold py-[14px] px-8 h-12 flex items-center rounded-lg`}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn bg-custom-primary text-white font-semibold py-[14px] px-8 h-12 flex items-center rounded-lg disabled:opacity-75"
              disabled={loading}
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddPhoneNumber;
