import Drawer from "@mui/material/Drawer";
import React, { Fragment, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Login from "dialogs/Login";
import ForgotPasswordVerifyEmail from "dialogs/ForgotPassVerifyEmail";
import { Paths } from "routes";
import { mainNavbarItems } from "utils/common";
import { useLocation, useNavigate } from "react-router-dom";
import { CrossIcon, HamburgerIcon } from "assets";

const GuestHeader = () => {
  const location = useLocation();
  
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [loginPopup, setLoginPopup] = useState(false);
  const [forgotPasswordPopup, setForgotPasswordPopup] = useState(false);

  const renderNav = () => {
    return (
      <ul
        className={`w-full items-center hidden md:flex gap-7`}
      >
        {mainNavbarItems.map((item) => (
          <li
            className={`cursor-pointer whitespace-pre text-[#353535] text-[15px] leading-5 font-medium hover:text-custom-primary`}
            onClick={() => {
              item.id === "contact-us"
                ? navigate(Paths.ContactUs)
                : navigate(`/${item.id}`)
            }}
          >
            {item.label}
          </li>
        ))}
      </ul>
    );
  };
  
  return (
    <Fragment>
      {isSidebarOpen && (
        <Drawer
          anchor="left"
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          classes={{
            paper: "p-4",
          }}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onKeyDown={() => setIsSidebarOpen(false)}
            className="h-full"
          >
            <div className="flex justify-between items-center">
              <h5 className="m-0 md:hidden block text-[#121212] font-semibold text-2xl leading-8 tracking-[0.48px] cursor-pointer md:pr-6 pr-0 md:border-r border-r-0 border-[#E5E5E5]">
                Dial<span className="text-[#2764D2]">r</span>
              </h5>
              <div
                className="w-6 h-6 flex justify-center items-center cursor-pointer"
                onClick={() => setIsSidebarOpen(false)}
              >
                <img src={CrossIcon} alt="close-icon" />
              </div>
            </div>
            <List className="!p-0 h-full flex flex-col gap-3 !mt-5">
              {mainNavbarItems.map((item, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  onClick={() => {
                    item.id === "contact-us"
                      ? navigate(Paths.ContactUs)
                      : navigate(`/${item.id}`)
                  }}
                  className="!cursor-pointer"
                >
                  <ListItemButton className="!p-0">
                    <ListItemText
                      primary={item.label}
                      classes={{
                        primary:
                          "text-[#353535] !text-lg !leading-8 !font-medium whitespace-nowrap",
                      }}
                      className="!m-0"
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      )}
      {loginPopup && (
        <Login
          open={loginPopup}
          setOpen={setLoginPopup}
          handleForgotPassword={() => {
            setForgotPasswordPopup(true);
            setLoginPopup(false);
          }}
        />
      )}

      <ForgotPasswordVerifyEmail
        open={forgotPasswordPopup}
        setOpen={setForgotPasswordPopup}
      />

      <nav className="bg-white py-[14px] w-full">
        <div className="xl:max-w-[1114px] lg:max-w-[1024px] md:max-w-[640px] max-w-full md:mx-auto mx-4 flex justify-between items-center gap-5">
          <div className="flex items-center gap-8">
            <button
              className="md:hidden p-2 bg-transparent border border-[#ffffff1a] rounded"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            >
              <img src={isSidebarOpen ? CrossIcon : HamburgerIcon} alt="menu" />
            </button>
            <h5 
            className="m-0 md:block hidden text-[#121212] font-semibold text-2xl leading-8 tracking-[0.48px] cursor-pointer md:pr-6 pr-0 md:border-r border-r-0 border-[#E5E5E5]"
            onClick={() => navigate("/")}
            >
              Dial<span className="text-[#2764D2]">r</span>
            </h5>
            {renderNav()}
          </div>
          <div className="flex items-center gap-7">
            <button
              className="btn w-full min-w-[107px] bg-custom-primary text-white font-semibold leading-5 py-3 px-[22px] whitespace-nowrap rounded-lg disabled:opacity-75"
              onClick={() => {
                location.pathname === "/login" ? navigate(Paths.SignUp) : setLoginPopup(true)
              }}
            >
              {location.pathname === "/login" ? "Sign up" : "Login"}
            </button>
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

export default GuestHeader;