import { forwardRef } from "react";
import { Dialog, DialogContent, DialogContentText, Slide } from "@mui/material";
import { CrossIcon } from "assets";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteAccount = ({ open, setOpen, handleSubmit, infoDetail, icon, loading = false }) => {
  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  const handleOkayClick = () => {
    setOpen(false);
    handleSubmit();
  };
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          style: {
            maxWidth: "506px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent className="!py-[52px] !px-[92px] relative">
          <DialogContentText id="alert-dialog-slide-description">
            <img
              src={CrossIcon}
              alt="crossIcon"
              className="ml-auto cursor-pointer absolute top-4 right-4"
              onClick={handleClose}
            />
            <div className="max-w-[322px] mx-auto flex flex-col items-center font-hankengrotesk">
              <div className="w-[88px] h-[88px] bg-[rgb(246,249,253)] flex justify-center items-center rounded-full">
                <img src={icon} alt="icon" className="w-full h-full" />
              </div>

              <div className="w-full flex flex-col gap-2 mt-4">
                <div className="font-semibold text-[28px] leading-9 text-[#353535] text-center ">
                  {infoDetail.heading}
                </div>
                {infoDetail?.innerContent && (
                  <div className="text-md text-center text-[#1F1F1F]">
                    {infoDetail?.innerContent}
                  </div>
                )}
              </div>
              <div className="flex items-center justify-center gap-4 w-full mt-8">
                <button
                  className="btn bg-red-500 text-white font-medium  py-[14px] px-8 h-12 flex items-center justify-center rounded-lg w-full max-w-[180px]  disabled:opacity-75"
                  onClick={handleOkayClick}
                  disabled={loading}
                >
                  {loading ? "Deleting..." : "Yes, Delete"}
                </button>
                <button
                  className="btn border border-custom-primary text-black font-medium py-[14px] px-8 h-12 flex items-center justify-center rounded-lg w-full max-w-[180px]  disabled:opacity-75"
                  onClick={handleClose}
                >
                  No
                </button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeleteAccount;
