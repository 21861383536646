import React from "react";
import { ChatConversationIcon } from "assets";
import Loader from "component/Loader";
import ChatListItem from "./chatListItem";
import { useNavigate } from "react-router-dom";

export default function ChatListSidebar({
  jobId,
  chatId,
  chatListLoader,
  chatList,
  setChatList,
  setChatMessages,
}) {
  const navigate = useNavigate();

  return (
    <div className="w-full max-w-[200px] flex flex-col justify-between items-center border-r border-[#E5E5E5]">
      {chatListLoader ? (
        <Loader />
      ) : chatList && chatList?.length > 0 ? (
        <div className="w-full h-full max-h-[calc(100%_-_94px)] flex flex-col items-start gap-[6px] px-4 pt-9 overflow-auto scrollbar">
          {chatList.map((chat) => (
            <ChatListItem
              chatId={chatId}
              id={chat.id}
              chatTitle={chat.title}
              onChatClick={() => {
                navigate(`/${jobId}/ai-chat/${chat.id}`);
              }}
              handleCallBack={(action) => {
                action === "delete" &&
                  setChatList([...chatList.filter((c) => c.id !== chat.id)]);
                setChatMessages([]);
                navigate(`/${jobId}/ai-chat`);
              }}
            />
          ))}
        </div>
      ) : (
        <div className="bg-white flex flex-col gap-2 px-4 pt-9">
          <div className="p-[5px] flex justify-center items-center">
            <img
              src={ChatConversationIcon}
              width={18}
              height={18}
              alt="chat-icon"
            />
          </div>
          <span className="text-xs font-normal leading-4 text-[#061025B2] text-center">
            Your saved saved chats will appear here
          </span>
        </div>
      )}
      <div className="w-full pb-[30px] px-4 pt-4">
        <div
          className="cursor-pointer w-full btn bg-custom-primary h-[48px] flex items-center justify-center border-custom-primary border-[1px] border-purple font-medium text-[13px] leading-[16px] text-[#fff] py-[14px] px-[32px] rounded-lg"
          onClick={() => {
            setChatMessages([]);
            navigate(`/${jobId}/ai-chat`);
          }}
        >
          New Chat
        </div>
      </div>
    </div>
  );
}
