import { ArrowRightPrimary } from "assets";
import React from "react";

export default function TemplatePromtExample({ setMsgInput }) {
  const promtExamples = [
    "From the conversations you've had who is on a 30k salary?",
    "Give me a list of 5 candidates that are the best fit for this position",
    "Give me a list of candidates who declined the call",
    "Regardless of salary expectations, who is the best candidate?",
  ];
  return promtExamples.map((promt) => {
    return (
      <div
        className="w-full bg-[#F6F9FD] py-5 pl-5 pr-3 cursor-pointer rounded-xl"
        onClick={() => setMsgInput(promt)}
      >
        <div className="flex gap-[37px] justify-center items-center">
          <div className="w-full max-w-[231px] font-normal text-[14px] text-[#393939] leading-5">
            {promt}
          </div>
          <img src={ArrowRightPrimary} alt="icon" />
        </div>
      </div>
    );
  });
}
