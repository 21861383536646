import React from "react";

export default function Stepper({ steps, selectedStep }) {
  return (
    <div class="relative after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-full after:h-1 after:bg-gray-200 after:z-0">
      <div className="flex justify-center items-center sm:gap-0 gap-3 overflow-auto relative">
        {steps.map((stepDetail, index) => {
          return (
            <div class="flex-1 text-center relative z-10">
              <span class={`text-[#121212] block py-[10px] text-[13px] leading-[15px] font-medium whitespace-nowrap  ${selectedStep !== index ? "opacity-40" : "opacity-100"}`}>{stepDetail}</span>
              <div
                class={`w-full h-1  absolute bottom-0 left-0 rounded  ${
                  selectedStep === index
                    ? " bg-custom-primary"
                    : ""
                }`}
              ></div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
