import axiosInstance from "utils/axiosInstance";
import { endPoints } from "./endPoints";

export const fetchSubscription = async () => {
  return await axiosInstance.get(endPoints.subscription.fetchSubscription);
};

export const createCheckoutSession = async (
  price = "monthly1",
  skipTrial = false,
  isAfterSignUp = false
) => {
  return await axiosInstance.post(
    endPoints.subscription.createCheckoutSession,
    {
      price,
      skipTrial,
      from: window.location.protocol + "//" + window.location.host,
      isAfterSignUp,
    }
  );
};
