import { useState, forwardRef, useEffect } from "react";
import { Dialog, DialogContent, Slide } from "@mui/material";
import { CompletedGreen, CrossIcon, EyeIcon, EyeOffIcon } from "assets";
import InfoDialog from "./InfoDialog";
import { changePassword, updatePassword } from "services/Auth";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChangePassword = ({
  open,
  setOpen,
  email,
  isUpdatePassword = false,
  verifiedOtpCode = "",
}) => {
  const [loading, setLoading] = useState(false);
  const [infoDialoag, setInfoDialoag] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  useEffect(() => {
    if (!open) {
      setErrors({});
      setFormData({ password: "", confirmPassword: "" });
      setShowPassword({ password: false, confirmPassword: false });
    }
  }, [open]);

  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let newErrors = { ...errors, submit: "" };
    let validateFieldName = name;

    if (isUpdatePassword) {
      validateFieldName = name === "password" ? "Old password" : "New password";
    } else {
      validateFieldName = name === "password" ? "Password" : "Confirm Password";
    }
    if (!value) {
      newErrors[name] = `${validateFieldName} is required`;
    } else if (
      !isUpdatePassword &&
      name === "confirmPassword" &&
      value !== formData.password
    ) {
      newErrors[name] = "Passwords do not match";
    } else {
      delete newErrors[name];
    }

    setErrors(newErrors);
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.password) {
      newErrors.password = `${
        isUpdatePassword ? "Old password" : "Password"
      } is required`;
    }
    if (!formData.confirmPassword) {
      newErrors.confirmPassword = `${
        isUpdatePassword ? "New password" : "Confirm"
      } Password is required`;
    } else if (
      !isUpdatePassword &&
      formData.confirmPassword !== formData.password
    ) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setLoading(true);
        let resp;
        if (isUpdatePassword) {
          resp = await updatePassword({
            OldPassword: formData.password,
            NewPassword: formData.confirmPassword,
          });
        } else {
          resp = await changePassword({
            Email: email,
            Password: formData.password,
            Otp: verifiedOtpCode,
          });
        }

        setLoading(false);
        if (resp?.data?.result) {
          handleClose();
          setInfoDialoag(true);
        }
      } catch (err) {
        setLoading(false);
        if (err?.response?.data?.title) {
          setErrors({
            submit: err?.response?.data?.title,
          });
        }
      }
    }
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const renderInputField = (name, label, type = "text", placeholder) => (
    <div className="relative">
      <label className="text-[13px] leading-5 font-hankengrotesk font-normal text-[#1F1F1F] -tracking-[0.13px]">
        {label}
      </label>
      <input
        type={showPassword[name] ? "text" : type}
        name={name}
        value={formData[name]}
        onChange={handleChange}
        placeholder={placeholder}
        className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-[14px] px-4 block w-full h-12 outline-none"
      />
      {type === "password" && (
        <span
          onClick={() => togglePasswordVisibility(name)}
          className={`absolute right-4 top-1/2 cursor-pointer ${
            errors[name] ? "-translate-y-1/2" : ""
          }`}
        >
          <img src={showPassword[name] ? EyeOffIcon : EyeIcon} alt="eye" />
        </span>
      )}
      {errors[name] && (
        <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
          {errors[name]}
        </div>
      )}
    </div>
  );

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        style: {
          maxWidth: "564px",
          borderRadius: "12px",
        },
      }}
    >
      <DialogContent className="!py-[52px] !px-[92px] relative">
        {infoDialoag && (
          <InfoDialog
            open={infoDialoag}
            setOpen={setInfoDialoag}
            infoDetail={{
              heading: `Password Changed!.`,
              innerContent: "",
            }}
            handleSubmit={() => {}}
            icon={CompletedGreen}
          />
        )}
        <img
          src={CrossIcon}
          alt="crossIcon"
          className="ml-auto cursor-pointer absolute top-4 right-4"
          onClick={handleClose}
        />
        <form
          onSubmit={handleSubmitForm}
          className="flex flex-col font-hankengrotesk"
        >
          <div className="text-[28px] leading-9 font-semibold text-left mb-5 text-[#353535]">
            Create New Password
          </div>
          <div className="flex flex-col gap-[18px]">
            {renderInputField(
              "password",
              isUpdatePassword ? "Old Password" : "New Password",
              "password",
              isUpdatePassword ? "Old Password" : "New Password"
            )}
            {renderInputField(
              "confirmPassword",
              isUpdatePassword ? "New Password" : "Confirm New Password",
              "password",
              isUpdatePassword ? "New Password" : "Confirm New Password"
            )}
          </div>
          {errors?.submit && (
            <div className="w-full text-sm font-medium text-[#FF352A] mt-[22px] mb-3 ">
              {errors.submit}
            </div>
          )}
          <div className="w-full flex justify-between flex-row gap-3 sm:gap-0 mt-11">
            <button
              type="button"
              onClick={() => handleClose()}
              className={`btn bg-white border-custom-primary border-[1px] text-black border-purple font-semibold py-[14px] px-8 h-12 flex items-center rounded-lg`}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn bg-custom-primary text-white font-semibold py-[14px] px-8 h-12 flex items-center rounded-lg disabled:opacity-75"
              disabled={loading}
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePassword;
