import { useState, Fragment, useRef } from "react";
import Stepper from "component/Stepper";
import EmailVerificationOtp from "dialogs/EmailVerificationOtp";
import InfoDialog from "dialogs/InfoDialog";
import { EyeIcon, EyeOffIcon, InfoIcon } from "assets";
import { sendSignUpOtp, signUpService } from "services/Auth";
import { useNavigate, useLocation } from "react-router-dom";
import Login from "dialogs/Login";
import ForgotPasswordVerifyEmail from "dialogs/ForgotPassVerifyEmail";
import HCaptcha from "@hcaptcha/react-hcaptcha";

const SignSteps = ["Personal Info", "Company Details"]; // "AI Suggestions"

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export default function IndexV2() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramsEmail = searchParams.get("email");
  const captchaRef = useRef(null);

  const initialValues = {
    firstname: "",
    lastname: "",
    email: paramsEmail || "",
    phone: "+44",
    password: "",
    confirmpassword: "",
    companyName: "",
    industry: "",
    manualDetail: "",
    lookingJobTitles: "",
  };

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [verificationOtpPopup, setVerificationOtpPopup] = useState(false);
  const [verificationInfoPopup, setVerificationInfoPopup] = useState(false);
  const [loginPopup, setLoginPopup] = useState(false);
  const [forgotPasswordPopup, setForgotPassword] = useState(false);
  const [verificationProgessInfoPopup, setVerificationProgessInfoPopup] =
    useState(false);
  const [captchaKey, setCaptchaKey] = useState(Date.now());

  const errorsField = {
    firstname: "First name",
    lastname: "Last name",
    email: "Email",
    password: "Password",
    confirmpassword: "Confirm password",
    companyName: "Company name",
    industry: "Industry",
    lookingJobTitles: "Job title",
    captchaCode: "Captcha code",
  };
  const validateStep = (step) => {
    let newErrors = {};

    if (step === 0) {
      if (!formValues.firstname) newErrors.firstname = "First name is required";
      if (!formValues.lastname) newErrors.lastname = "Last name is required";
      if (!formValues.email) {
        newErrors.email = "Email is required";
      } else if (!isValidEmail(formValues.email)) {
        newErrors.email = "Please enter a valid email address";
      }
      if (!formValues.password) newErrors.password = "Password is required";
      if (!formValues.confirmpassword)
        newErrors.confirmpassword = "Confirm password is required";
      else if (formValues.password !== formValues.confirmpassword)
        newErrors.confirmpassword = "Passwords do not match";
      if (formValues.phone) {
        const formattedValue = formValues.phone.replace(/\D/g, "");
        if (
          formattedValue.includes("44") &&
          formattedValue.length !== 2 &&
          formattedValue.length !== 12
        ) {
          // phone numbers have 44 + 10 digits
          newErrors.phone = "Please enter a valid UK phone number";
        }
      }
    }

    if (step === 1) {
      if (!formValues.companyName)
        newErrors.companyName = "Company name is required";
      if (!formValues.industry) newErrors.industry = "Industry is required";
      if (!formValues.lookingJobTitles)
        newErrors.lookingJobTitles = "Job title is required";
      if (!formValues.captchaCode)
        newErrors.captchaCode = "Captcha code is required";
    }

    // if (step === 2) {
    // if (!formValues.manualDetail)
    //   newErrors.manualDetail = "Conversation features are required";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = async () => {
    if (validateStep(step)) {
      if (step === 1) {
        setVerificationOtpPopup(true);
        try {
          await sendSignUpOtp({
            FirstName: formValues.firstname,
            LastName: formValues.lastname,
            Password: formValues.password,
            Email: formValues.email,
            CaptchaCode: formValues.captchaCode,
          });
        } catch (err) {
          console.log("error", err);
          if (err?.response?.data?.code === "email_already_exists") {
            setVerificationProgessInfoPopup(true);
          }
          setVerificationOtpPopup(false);
        }
      } else setStep(step + 1);
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleFieldChange = (fieldName, value) => {
    const newValues = { ...formValues, [fieldName]: value };
    setFormValues(newValues);

    // Real-time validation for changed field
    let newErrors = { ...errors };
    if (fieldName === "email") {
      if (!value) {
        newErrors.email = "Email is required";
      } else if (!isValidEmail(value)) {
        newErrors.email = "Please enter a valid email address";
      } else {
        delete newErrors.email;
      }
    } else if (fieldName === "phone") {
      const formattedValue = value.replace(/\D/g, "");
      if (formattedValue.includes("44") && formattedValue.length === 2) {
        delete newErrors.phone;
      } else if (formattedValue.length !== 12) {
        // phone numbers have 44 + 10 digits
        newErrors.phone = "Please enter a valid UK phone number";
      } else {
        delete newErrors.phone;
      }
    } else if (fieldName === "confirmpassword") {
      if (newValues.password !== newValues.confirmpassword) {
        newErrors.confirmpassword = "Passwords do not match";
      } else {
        delete newErrors[fieldName];
      }
    } else {
      if (!value) {
        newErrors[fieldName] = `${errorsField[fieldName]} is required`;
      } else {
        delete newErrors[fieldName];
      }
    }

    setErrors(newErrors);
  };

  const renderInputField = (name, placeholder, label, type = "text") => (
    <div className="flex flex-col gap-1">
      {renderLabel(label)}
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={formValues[name]}
        onBlur={() => handleFieldChange(name, formValues?.[name]?.trim())}
        onChange={(e) => {
          const inputValue = e.target.value;
          if (name === "phone") {
            // Ensure +44 prefix remains intact and allow only digits after +44
            const phoneValue = "+44" + inputValue.slice(3).replace(/\D/g, "");
            handleFieldChange(name, phoneValue);
          } else {
            handleFieldChange(name, inputValue);
          }
        }}
        className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none"
      />
      {errors[name] && (
        <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
          {errors[name]}
        </div>
      )}
    </div>
  );

  const renderLabel = (label) => (
    <label className="text-[#1F1F1F] text-[13px] font-normal leading-5 -tracking-[0.13px] ">
      {label}
    </label>
  );

  const handleSignUp = async (verificationCode) => {
    try {
      setLoading(true);
      const userData = await signUpService({
        FirstName: formValues.firstname,
        LastName: formValues.lastname,
        Password: formValues.password,
        Email: formValues.email,
        Phone: formValues.phone?.trim("") === "+44" ? "" : formValues.phone,
        CompanyName: formValues.companyName,
        Industry: formValues.industry,
        JobTitleList: formValues.lookingJobTitles,
        AiSuggestions: formValues.manualDetail,
        CaptchaCode: "Captcha",
        Otp: verificationCode, // [Otp validation already done so this is not needed]
      });
      setLoading(false);
      setVerificationOtpPopup(false);
      if (userData?.data?.Status === "review") {
        setVerificationInfoPopup(true);
      }
      return userData?.data;
    } catch (error) {
      setLoading(false);
      // if (error?.response?.data?.code !== "otp_invalid")
      //   setVerificationOtpPopup(false);
      if (error?.response?.data?.code === "email_already_exists") {
        setVerificationOtpPopup(false);
        setVerificationProgessInfoPopup(true);
      }
      return error?.response?.data;
    }
  };

  const handleResendotp = async (captchaCode) => {
    try {
      await sendSignUpOtp({
        FirstName: formValues.firstname,
        LastName: formValues.lastname,
        Password: formValues.password,
        Email: formValues.email,
        CaptchaCode: captchaCode,
      });
    } catch (err) {
      console.log("err", err);
      if (err?.response?.data?.code === "email_already_exists") {
        setVerificationProgessInfoPopup(true);
      }
    }
  };

  return (
    <div className="w-full sm:max-w-[420px] max-w-full mx-auto md:px-0 px-4">
      <h4 className="my-7 text-[#2E2E2E] text-2xl text-center font-medium leading-7 -tracking-[0.24px] ">
        Sign up
      </h4>

      <div className="flex flex-col gap-7">
        <Stepper selectedStep={step} steps={SignSteps} />

        {loginPopup && (
          <Login
            open={loginPopup}
            setOpen={setLoginPopup}
            handleForgotPassword={() => {
              setForgotPassword(true);
              setLoginPopup(false);
            }}
          />
        )}

        <ForgotPasswordVerifyEmail
          open={forgotPasswordPopup}
          setOpen={setForgotPassword}
        />

        {verificationOtpPopup && (
          <EmailVerificationOtp
            open={verificationOtpPopup}
            setOpen={(open) => {
              setVerificationOtpPopup(open);
              setFormValues((prev) => ({ ...prev, captchaCode: "" }));
              setCaptchaKey(Date.now());
            }}
            handleSubmit={handleSignUp}
            loading={loading}
            email={formValues.email}
            handleResend={handleResendotp}
            callFrom={"signup"}
          />
        )}
        {verificationInfoPopup && (
          <InfoDialog
            open={verificationInfoPopup}
            setOpen={(open) => {
              setVerificationInfoPopup(open);
              navigate("/");
            }}
            infoDetail={{
              heading: "",
              innerContent:
                // "We're verifying your application, this usually takes 24hours. Once we verify your data we'll send you an email with a login link.",
                "Thank you for your interest. We’re due to be going live very soon. We’re reviewing all applications as fast as we can and will send an email once you can begin using the platform.",
              innerContentClass: "text-lg",
            }}
            handleSubmit={() => {
              // setFormValues(initialValues);
              // setStep(0);
              // setLoginPopup(true);
              navigate("/");
            }}
            icon={InfoIcon}
          />
        )}
        {verificationProgessInfoPopup && (
          <InfoDialog
            open={verificationProgessInfoPopup}
            setOpen={setVerificationProgessInfoPopup}
            infoDetail={{
              heading: "This email address is already under review",
              innerContent: "",
            }}
            handleSubmit={() => {
              setFormValues(initialValues);
              setStep(0);
              setLoginPopup(true);
            }}
            icon={InfoIcon}
          />
        )}
        <div className="flex flex-col gap-[52px]">
          <form
            onSubmit={(e) => e.preventDefault()}
            className="flex flex-col gap-[18px]"
          >
            {step === 0 && (
              <>
                <div className="flex md:flex-row flex-col gap-5">
                  {renderInputField("firstname", "Ex John", "First Name")}
                  {renderInputField("lastname", "Ex Doe", "Last Name")}
                </div>
                {renderInputField(
                  "email",
                  "Ex john.doe@example.com",
                  "Work Email Address"
                )}
                {renderInputField(
                  "phone",
                  "Ex +44 7700 900000 (Optional)",
                  "Phone Number (Optional)"
                )}
                <div className="relative">
                  {renderInputField(
                    "password",
                    "Password",
                    "Password",
                    showPassword ? "text" : "password"
                  )}
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    className={`absolute right-4 top-1/2 cursor-pointer ${
                      errors["password"] ? "-translate-y-1/2" : ""
                    }`}
                  >
                    <img src={showPassword ? EyeOffIcon : EyeIcon} alt="eye" />
                  </span>
                </div>
                <div className="relative">
                  {renderInputField(
                    "confirmpassword",
                    "Confirm Password",
                    "Confirm Password",
                    showConfirmPassword ? "text" : "password"
                  )}
                  <span
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className={`absolute right-4 top-1/2 cursor-pointer ${
                      errors["confirmpassword"] ? "-translate-y-1/2" : ""
                    }`}
                  >
                    <img
                      src={showConfirmPassword ? EyeOffIcon : EyeIcon}
                      alt="eye"
                    />
                  </span>
                </div>
              </>
            )}

            {step === 1 && (
              <>
                {renderInputField(
                  "companyName",
                  "Company Name",
                  "Company Name"
                )}
                {renderInputField("industry", "Industry", "Industry")}
                <div className="flex flex-col gap-1">
                  {renderLabel("Typical Job Titles you Hire for")}
                  <textarea
                    name="lookingJobTitles"
                    rows="5"
                    placeholder={`Ex Scrum Master, Python Developer..."`}
                    value={formValues.lookingJobTitles || ""}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        lookingJobTitles: e.target.value,
                      });
                      handleFieldChange("lookingJobTitles", e.target.value);
                    }}
                    className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none resize-none"
                  />
                  {errors.lookingJobTitles && (
                    <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A] ">
                      {errors.lookingJobTitles}
                    </div>
                  )}
                  <div className="flex flex-col gap-1 mt-5">
                    <HCaptcha
                      key={captchaKey} // Use key to force remount
                      ref={captchaRef}
                      sitekey="e0babe09-2202-4ea1-9882-55edfc867418"
                      onVerify={(token) => {
                        handleFieldChange("captchaCode", token);
                      }}
                      onExpire={() => {
                        handleFieldChange("captchaCode", "");
                      }}
                      className="flex"
                    />
                    {Boolean(errors.captchaCode) && (
                      <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                        {errors.captchaCode}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            {/* {step === 2 && (
              <>
                <div className="flex flex-col gap-3">
                  <div className="flex flex-col gap-1">
                    <h2 className="text-base text-[#1F1F1F] leading-[22px] -tracking-[1%] font-medium ">
                      Conversation features
                    </h2>
                    <h3 className="text-[#1F1F1F] text-[13px] leading-5 -tracking-[1%] font-light ">
                      What key conversation features would you expect a typical
                      conversation between yourself and a potential candidate
                      have?
                    </h3>
                  </div>
                  <textarea
                    name="manualDetail"
                    rows="7"
                    placeholder={`Ex "Ask if they'd be down to take a salary reduction for work life balance"`}
                    value={formValues.manualDetail || ""}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        manualDetail: e.target.value,
                      });
                      handleFieldChange("manualDetail", e.target.value);
                    }}
                    className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none resize-none"
                  />
                  {errors.manualDetail && (
                    <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A] ">
                      {errors.manualDetail}
                    </div>
                  )}
                  <HCaptcha
                    ref={captchaRef}
                    sitekey="e0babe09-2202-4ea1-9882-55edfc867418"
                    onVerify={(token) => {
                      handleFieldChange("captchaCode", token);
                    }}
                    onExpire={() => {
                      handleFieldChange("captchaCode", "");
                    }}
                    className="flex"
                  />
                  {Boolean(errors.captchaCode) && (
                    <div className="text-sm font-medium text-[#FF352A] mt-1 w-[300px] m-auto">
                      {errors.captchaCode}
                    </div>
                  )}
                </div>
              </>
            )} */}

            {errors.submit && (
              <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A] ">
                {errors.submit}
              </div>
            )}
          </form>
          <div>
            <div className="w-full flex  justify-between flex-row gap-3 sm:gap-0 pb-[34px]">
              <button
                type="button"
                className={` btn bg-white border-custom-primary border-[1px] text-black border-purple font-semibold py-[14px] px-[32px] rounded-lg ${
                  step === 0 ? "opacity-40 cursor-not-allowed" : ""
                }`}
                onClick={handleBack}
                disabled={step === 0}
              >
                Back
              </button>
              <button
                type="button"
                className=" btn bg-custom-primary text-white font-semibold py-[14px] px-[32px] rounded-lg disabled:opacity-75"
                onClick={handleNext}
              >
                Next
              </button>
            </div>
            {step === 2 && (
              <span className="text-[#121212] text-[14px] leading-5 font-semibold pb-12 cursor-pointer text-center mx-auto block">
                By clicking next, you are agreeing to our
                <br />
                <a
                  href="https://app.termly.io/policy-viewer/policy.html?policyUUID=c9e5e90a-fadc-424c-942f-13f8d14b92e7"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="text-[rgb(39,100,210)] font-bold"
                >
                  Terms and Conditions
                </a>{" "}
                and{" "}
                <a
                  href="https://app.termly.io/policy-viewer/policy.html?policyUUID=0b67ab5b-10ce-4dfc-b0c9-e289d9c54cdb"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="text-[rgb(39,100,210)] font-bold"
                >
                  Privacy Policy
                </a>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
