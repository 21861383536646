import React from 'react';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/system';

const WhiteTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: 'white',
    color: '#1F1F1F',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    fontSize: '12px',
    padding: "12px",
    fontFamily: "Hanken Grotesk"
  },
  [`& .MuiTooltip-arrow`]:{ 
    color: "#fff",
    filter: 'drop-shadow(2px 2px 16px rgba(0, 0, 0, 0.12))'
  }
});

const CustomTooltip = ({ title, children, ...props }) => {
  return (
    <WhiteTooltip title={title} {...props}>
      {children}
    </WhiteTooltip>
  );
};

export default CustomTooltip;
