import { forwardRef, useEffect, useState } from "react";
import { Dialog, DialogContent, DialogContentText, Slide } from "@mui/material";
import { CompletedGreen, CrossIcon } from "assets";
import EmailVerificationOtp from "./EmailVerificationOtp";
import InfoDialog from "./InfoDialog";
import ChangePassword from "./ChangePassword";
import { sendResetPasswordOtp } from "services/Auth";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ForgotPasswordVerifyEmail = (props) => {
  const { open, setOpen, loading = false } = props;

  const [loader, setLoading] = useState(""); // sendingOtp | verifyOtp
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [verificationPopup, setVerificationPopup] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [infoDialog, setInfoDialoag] = useState(false);
  const [verifiedOtpCode, setVerifiedOtpCode] = useState("");

  useEffect(() => {
    if (!open && !verificationPopup && !changePassword) {
      setErrors({});
      setEmail("");
    }
  }, [open, verificationPopup]);

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  const validate = (email) => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(email)) {
      newErrors.email = "Please enter a valid email address";
    } else {
      delete newErrors.email;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleVerifyEmail = async (e) => {
    e.preventDefault();
    if (validate(email)) {
      try {
        setLoading("sendingOtp");
        await sendResetPasswordOtp({
          Email: email,
        });
        setLoading("");
        handleClose();
        setVerificationPopup(true);
      } catch (err) {
        setLoading("");
        setErrors({ email: err?.response?.data?.title });
      }
    }
  };

  const handleEmailChange = (value) => {
    setEmail(value);
    validate(value);
  };

  const verifiedOtp = (verificationCode) => {
    setVerifiedOtpCode(verificationCode);
    setVerificationPopup(false);
    setChangePassword(true);
  };

  const handleResend = async () => {
    await sendResetPasswordOtp({
      Email: email,
    });
  };

  return (
    <>
      {verificationPopup && (
        <EmailVerificationOtp
          open={verificationPopup}
          setOpen={setVerificationPopup}
          handleSubmit={verifiedOtp}
          email={email}
          handleResend={handleResend}
        />
      )}
      <ChangePassword
        open={changePassword}
        setOpen={setChangePassword}
        email={email}
        verifiedOtpCode={verifiedOtpCode}
      />
      {infoDialog && (
        <InfoDialog
          open={infoDialog}
          setOpen={setInfoDialoag}
          infoDetail={{
            heading: "Account Deleted!",
            innerContent: "",
          }}
          handleSubmit={() => {}}
          icon={CompletedGreen}
        />
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          style: {
            maxWidth: "564px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent className="!py-[52px] !px-[92px] relative">
          <DialogContentText id="alert-dialog-slide-description">
            <img
              src={CrossIcon}
              alt="crossIcon"
              className="ml-auto cursor-pointer absolute top-4 right-4"
              onClick={handleClose}
            />
            <div className="flex flex-col justify-start items-start gap-11 max-w-[380px] w-full mx-auto font-hankengrotesk">
              <div className="flex flex-col gap-7">
                <div className="flex flex-col gap-2">
                  <h6 className="font-semibold text-[28px] leading-9 text-[#353535] m-0">
                    Forgot Password?
                  </h6>
                  <p className="text-[15px] text-[#1F1F1F] leading-[22px]  -tracking-[0.15px] font-light m-0">
                    Please enter the email address associated with your account
                    and click on verify button.
                  </p>
                </div>

                <form
                  noValidate
                  onSubmit={handleVerifyEmail}
                  className="flex flex-col"
                >
                  <div className="flex flex-col gap-1">
                    <label className="text-[13px] font-normal leading-5 -tracking-[0.15px] text-[#1F1F1F] ">
                      Email Address
                    </label>
                    <input
                      name="email"
                      placeholder="Ex john.doe@example.com"
                      onChange={(event) =>
                        handleEmailChange(event.target.value)
                      }
                      onBlur={() => handleEmailChange(email?.trim())}
                      value={email}
                      className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-[14px] px-4 block w-full h-12 outline-none"
                    />
                    {errors.email && (
                      <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                        {errors.email}
                      </div>
                    )}
                  </div>
                </form>
              </div>
              <div className="w-full flex justify-between items-center gap-4">
                <button
                  type="button"
                  onClick={handleClose}
                  className="btn border border-custom-primary text-[#121212] font-semibold py-[14px] px-8 h-12 flex items-center rounded-lg disabled:opacity-75"
                >
                  Back
                </button>
                <button
                  disabled={loading || loader === "sendingOtp"}
                  type="button"
                  onClick={handleVerifyEmail}
                  className="btn bg-custom-primary text-white font-semibold py-[14px] px-8 h-12 flex items-center rounded-lg disabled:opacity-75"
                >
                  {loader === "sendingOtp" ? "Verifying..." : "Verify"}
                </button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ForgotPasswordVerifyEmail;
