import { PhoneCompletedIcon, PhoneMissedIcon } from "assets";
import React from "react";
import { formatDate } from "utils/common";
import { ClockIcon, ArrowLeft } from "assets";
import { useNavigate } from "react-router-dom";

export default function JobList({
  job,
  isFromJobDetail,
}) {
  const navigate = useNavigate();
  return (
    <div
      className={`flex flex-col sm:flex-row justify-between border border-[#E5E5E5] rounded-lg cursor-pointer ${
        isFromJobDetail
          ? "p-0 border-none cursor-auto mb-[10px]"
          : "p-4 sm:p-6 md:p-8"
      } `}
      onClick={() => !isFromJobDetail && navigate(`/${job?.id}`)}
    >
      <div className="flex flex-col gap-2 mb-4 sm:mb-0">
        <h3 className="text-lg sm:text-xl leading-[25px] -tracking-[-0.2px] font-medium text-[#121212]">
          {job?.title}
        </h3>
        <div className="flex items-center gap-[6px]">
          <h6 className="text-[#353535] text-xs sm:text-[13px] font-normal leading-[21px]">
            Date Posted:
          </h6>
          <img src={ClockIcon} alt="clock-icon" className="w-4 h-4" />
          <span className="text-[#1F1F1F] text-xs sm:text-[13px] font-light leading-[21px]">
            {formatDate(job?.updatedAt)}
          </span>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-6">
        <div className="flex gap-2">
          <div className="flex items-center gap-2 bg-green-100 text-green-800 px-3 py-1 rounded-full">
            <img
              src={PhoneCompletedIcon}
              alt="PhoneCompletedIcon"
              className="w-4 h-4"
            />
            <span className="text-xs sm:text-[13px] font-medium leading-5 text-[#121212]">
              {job?.successfulCallsCount || 0}
            </span>
          </div>
          <div className="flex items-center gap-2 bg-red-100 text-red-800 px-3 py-1 rounded-full">
            <img
              src={PhoneMissedIcon}
              alt="PhoneMissedIcon"
              className="w-4 h-4"
            />
            <span className="text-xs sm:text-[13px] font-medium leading-5 text-[#121212]">
              {job?.failedCallsCount || 0}
            </span>
          </div>
        </div>
        <div className="sm:border-l border-[#E5E5E5] sm:pl-4 md:pl-6 flex flex-col justify-center items-start gap-[2px]">
          <h6 className="text-[#727272] text-xs font-light leading-[18px]">
            Total Candidates
          </h6>
          <span className="text-[#121212] text-sm font-semibold leading-5">
            {job?.candidateCount || 0} Candidates
          </span>
        </div>
        {!isFromJobDetail && (
          <div className="border border-[#E5E5E5] rounded p-2 sm:p-3 flex justify-center items-center">
            <img src={ArrowLeft} alt="right-arrow" className="w-4 h-4" />
          </div>
        )}
      </div>
    </div>
  );
}
