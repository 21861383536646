import { CheckIcon } from "assets";
import React from "react";

export default function SubscriptionCard({
  plan,
  onClick,
  isFromDialog,
  className = "",
}) {
  const {
    planName,
    planPrice,
    priceDetail,
    includesFeatures,
    isMostPopularPlan,
    isCustomPrice,
    buttonText,
  } = plan;

  return (
    <div
      className={`w-full sm:h-full h-auto sm:min-h-[484px] min-h-fit sm:pb-0 pb-6 flex justify-start items-center pt-6 px-4 flex-col rounded-[10px] ${
        isMostPopularPlan
          ? `${
              isFromDialog
                ? "border-[4px] border-custom-primary after:content-none"
                : ""
            } bg-[rgba(255,255,255,0.95)] relative z-10 after:content-[''] after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:border-[6px] after:border-[rgba(195,217,255,0.40)] after:rounded-[10px] after:w-[calc(100%_+_6px)] after:h-[calc(100%_+_6px)] after:-z-[1]`
          : ""
      } ${
        isFromDialog
          ? "bg-[#FFF] border border-[#E5E5E5] rounded-md"
          : "bg-[rgba(217,219,253,0.07)]"
      } ${className}`}
    >
      {isMostPopularPlan && (
        <div
          className={`w-auto py-2 px-[18px] lg:mb-0 mb-3 rounded-[41px] ${
            isFromDialog
              ? "bg-[rgba(39,100,210,0.10)]"
              : "lg:bg-[#2764D21F] bg-[#121212]"
          } flex justify-center items-center lg:absolute static -top-[60px] left-1/2 lg:-translate-x-1/2 translate-x-0 whitespace-nowrap`}
        >
          <span
            className={`${
              isFromDialog ? "text-custom-primary" : ""
            } text-sm font-medium leading-[22px] tracking-[0.42px] uppercase`}
            style={{
              background: isFromDialog
                ? "unset"
                : "linear-gradient(89deg, #FFF 21.38%, #B5CFFF 89.92%)",
              backgroundClip: "text",
              WebkitTextFillColor: !isFromDialog ? "transparent" : "",
            }}
          >
            most popular
          </span>
        </div>
      )}
      <span
        className={`${
          isFromDialog
            ? "text-[#061025]"
            : isMostPopularPlan
            ? "text-[#061025]"
            : "text-white"
        } text-center xl:text-lg text-base font-medium xl:leading-[22px] sm:leading-5 leading-4 -tracking-[0.18px]`}
      >
        {planName}
      </span>
      {isCustomPrice ? (
        <div
          className={`sm:mt-9 mt-6 sm:mb-[38px] mb-7 h-full ${
            isFromDialog ? "lg:min-h-[84px] sm:min-h-[134px] min-h-[84px]" : ""
          } lg:max-h-[84px] sm:max-h-[134px] max-h-[84px] flex items-center`}
        >
          <h6
            className={`${
              isFromDialog ? "text-[#121212]" : "text-white"
            } xl:text-[40px] text-4xl font-semibold leading-9`}
          >
            Custom
          </h6>
        </div>
      ) : (
        <div className="sm:mt-9 mt-6 sm:mb-[38px] mb-7 flex flex-col items-center">
          <h6
            className={`${
              isMostPopularPlan || isFromDialog
                ? "text-[#121212]"
                : "text-white"
            } xl:text-[40px] text-4xl font-semibold leading-9`}
          >
            {planPrice}
          </h6>
          {priceDetail?.duration && (
            <div
              className={`my-1 ${
                isMostPopularPlan || isFromDialog
                  ? "text-[#121212]"
                  : "text-white"
              } text-lg font-medium leading-[22px] -tracking-[0.18px]`}
            >
              /{priceDetail?.duration}{" "}
              {priceDetail?.saving && (
                <span className="text-[#48BE55]">
                  (Save {priceDetail?.saving})
                </span>
              )}
            </div>
          )}
          {priceDetail?.annualBill && (
            <span
              className={`${
                isMostPopularPlan || isFromDialog
                  ? "text-[#353535]"
                  : "text-white"
              } invisible text-[13px] font-light leading-[18px] opacity-[0.65] whitespace-nowrap`}
            >
              *billed as {priceDetail?.annualBill} Annually
            </span>
          )}
        </div>
      )}

      <button
        type="button"
        className="w-full mb-6 py-3 px-4 bg-custom-primary rounded-md border border-custom-primary text-white text-[13px] font-medium leading-4 capitalize"
        onClick={(e) => {
          if (onClick) onClick(e);
        }}
      >
        {isCustomPrice ? "Contact us" : buttonText}
      </button>
      {includesFeatures && (
        <div className="w-full flex flex-col justify-start gap-[18px]">
          {includesFeatures?.map((feature) => {
            return (
              <div className="flex items-center gap-2">
                <div
                  className={`w-5 h-5 ${
                    isMostPopularPlan || isFromDialog
                      ? "bg-[#2764D21a]"
                      : "bg-[#ffffff1a]"
                  } flex justify-center items-center rounded-full`}
                >
                  <img src={CheckIcon} alt="check-icon" />
                </div>
                <span
                  className={`${
                    isMostPopularPlan || isFromDialog
                      ? "text-[#353535]"
                      : "text-white"
                  } text-sm font-medium leading-[22px] whitespace-nowrap`}
                >
                  {feature}
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
