import React, { useEffect, useState } from "react";
import Trim from "lodash/trim";
import ContentEditable from "react-contenteditable";
import { DeleteIcon, EditIcon, WarningInfoIcon } from "assets";
import SaveAsIcon from '@mui/icons-material/SaveAs';
import DeleteAccount from "dialogs/deleteAccount";
import { deleteChat, updateChatTitle } from "services/AIChat";

export default function ChatListItem({ onChatClick, chatTitle, id, chatId, handleCallBack }) {
  const chartTitleRef = React.createRef();
  const [title, setTitle] = useState(chatTitle);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleteDialog, setIsDeleteDialog] = useState(false);

  useEffect(() => {
    if (isEdit) {
      setTitle(Trim(title));
      setTitle(Trim(title) + " ");
    } else {
      if (chatTitle !== title) {
        updateChatTitle(title, id);
      }
    }
  }, [isEdit]);

  const handleDeleteChat = async () => {
    await deleteChat(id);
    handleCallBack("delete")
  };
  return (
    <div
      onClick={() => {
        if (!isEdit) onChatClick();
      }}
      className={`${!isEdit ? "cursor-pointer" : ""} w-full flex items-center py-[13px] pl-3 pr-[10px] rounded-lg bg-[#F9F9F9]`}
    >
      <ContentEditable
        innerRef={chartTitleRef}
        contentEditable={isEdit}
        html={title}
        disabled={!isEdit}
        onChange={(evt) => setTitle(evt.target.value)}
        className={`w-full max-w-[calc(100%_-_46px)] mr-[10px] ${isEdit ? "overflow-[unset]" : "overflow-ellipsis"} overflow-hidden whitespace-nowrap text-[13px] text-[#353535] font-normal leading-4 focus-visible:outline-none`}
      />
      <div className="flex gap-1">
        {isEdit ? (
          <SaveAsIcon
            onClick={(e) => {
              e.stopPropagation();
              if (title.length > 0) setIsEdit(false);
            }}
            className="!text-base"
          />
        ) : (
          <>
            <div className="cursor-pointer" onClick={(e) => {
              e.stopPropagation();
              setIsEdit(true)}
              }>
              <img src={EditIcon} width={16} height={16} alt="EditIcon" />
            </div>
          </>
        )}
        <div className="cursor-pointer" onClick={(e) => {
          e.stopPropagation();
          setIsDeleteDialog(true)
        }}>
          <img src={DeleteIcon} width={16} height={16} alt="DeleteIcon" />
        </div>
      </div>
      {isDeleteDialog && (
        <DeleteAccount
          open={isDeleteDialog}
          setOpen={setIsDeleteDialog}
          handleSubmit={handleDeleteChat}
          icon={WarningInfoIcon}
          infoDetail={{
            heading: "Are you sure you want to delete chat conversation?",
          }}
        />
      )}
    </div>
  );
}
