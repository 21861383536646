import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";

export default function MenuPopper({
  open,
  anchorRef,
  handleClose,
  menuItems,
  handleMenuClick,
}) {
  return (
    <Menu
      anchorEl={anchorRef}
      id="account-menu"
      open={open}
      onClose={handleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "fit-content",
          boxShadow: "0px 6px 20px 0px rgba(0, 0, 0, 0.10)",
          borderRadius: "4px",
          "& .MuiList-root": {
            padding: "0px",
            "& .MuiButtonBase-root": {
              display: "flex",
              alignItems: "center",
              gap: "8px",
              margin: "0px",
              borderBottom: "1px solid #E9E9E9",
              "&:last-child": {
                borderBottom: "none",
              },
              "&:hover": {
                textDecoration: "underline",
              },
            },
          },
        },
      }}
    >
      {menuItems.map((item, index) => (
        <MenuItem
          key={index}
          onClick={() => handleMenuClick(item.id)}
          disabled={item?.disabled || false}
        >
          <ListItemIcon className="!min-w-[auto]">
            <img src={item.icon} alt={item.label} className="w-4 h-4" />
          </ListItemIcon>
          <span className="text-[#353535] text-[13px] font-medium leading-[18px] ">
            {item.label}
          </span>
        </MenuItem>
      ))}
    </Menu>
  );
}
