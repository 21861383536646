import React, { useRef } from "react";
import trimStart from "lodash/trimStart";
import trim from "lodash/trim";
import { RegenerateIcon, CopyIconPrimary, DialerLogoCircleBW } from "assets";
import { useNotification } from "contexts/notificationContext";
import TypingLoader from "component/TypingLoader";
import { Typewriter } from "react-simple-typewriter";
import { goToChatMessageEnd } from "utils/common";

export default function ChatMessages({
  msgInput,
  chatMessages,
  lastChatId,
  msgSending,
  sendMessage,
}) {
  const lastCallTimeRef = useRef(Date.now());
  const { showNotification } = useNotification();

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showNotification("Copied successfully!", "success");
      })
      .catch((err) => {
        showNotification("Failed to copy!", "error");
      });
  };

  const handleOnType = () => {
    const now = Date.now();
    if (now - lastCallTimeRef.current >= 500) {
      goToChatMessageEnd();
      lastCallTimeRef.current = now;
    }
  };

  return (
    <div className="w-full flex flex-col gap-6">
      {chatMessages.map((chatMsg, index) => {
        return (
          <div
            className={`flex gap-[10px]   ${
              chatMsg.type === 1 ? "ml-auto" : ""
            }`}
          >
            {chatMsg?.type !== 1 && (
              <img src={DialerLogoCircleBW} width={32} height={32} alt="logo" />
            )}
            <div className="flex flex-col gap-[10px]">
              <div
                className={`w-full ${
                  chatMsg.type === 1
                    ? "max-w-[302px] bg-[#2764d20a]"
                    : "max-w-[332px] bg-[#F9F9F9]"
                } py-[10px] px-4 rounded-[4px_6px_6px_6px] text-[15px] text-[#353535] font-normal leading-6`}
              >
                {index === chatMessages.length - 1 &&
                chatMsg.id !== lastChatId ? (
                  <Typewriter
                    words={[trimStart(chatMsg.message)]}
                    // cursor
                    // cursorStyle="|"
                    typeSpeed={10}
                    onLoopDone={() => {
                      goToChatMessageEnd();
                    }}
                    onType={() => handleOnType()}
                  />
                ) : (
                  trimStart(chatMsg.message)
                )}
              </div>
              {index === chatMessages.length - 1 && !msgSending && (
                <div className="flex gap-[6px] ml-auto">
                  <div
                    className="flex items-center gap-[5px] py-[6px] px-4 bg-[#F9F9F9] rounded-[4px_6px_6px_6px] cursor-pointer"
                    onClick={() => {
                      sendMessage(
                        chatMessages[chatMessages.length - 2].message,
                        1
                      );
                    }}
                  >
                    <img src={RegenerateIcon} alt="RegenerateIcon" />
                    <span className="text-[11px] text-[#353535] font-normal leading-4 tracking-[0.2px] uppercase">
                      regenerate
                    </span>
                  </div>
                  <div
                    className="regenerae flex items-center gap-[5px] py-[6px] px-4 bg-[#F9F9F9] rounded-[4px_6px_6px_6px] cursor-pointer"
                    onClick={() => {
                      handleCopy(chatMessages[chatMessages.length - 1].message);
                    }}
                  >
                    <img src={CopyIconPrimary} alt="CopyIcon" />
                    <span className="text-[11px] text-[#353535] font-normal leading-4 tracking-[0.2px] uppercase">
                      copy text
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
      {msgSending && (
        <>
          <div className="flex flex-col gap-6">
            <div className="py-[10px] px-4 rounded-[4px_6px_6px_6px] max-w-[302px] bg-[#2764d20a]  ml-auto text-[15px] text-[#353535] font-normal leading-6">
              {trim(msgInput) || "TTTTT"}
            </div>
            <div className="flex gap-[10px]">
              <img src={DialerLogoCircleBW} width={32} height={32} alt="logo" />
              <div className="py-[10px] px-4 rounded-[4px_6px_6px_6px] min-w-[332px] text-[15px] text-[#353535] font-normal leading-6 bg-[#F9F9F9]">
                <TypingLoader />
              </div>
            </div>
          </div>
        </>
      )}
      <div id="list-bottom"></div>
    </div>
  );
}
