import { forwardRef } from "react";
import { Dialog, DialogContent, DialogContentText, Slide } from "@mui/material";
import { CrossIcon } from "assets";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InfoDialog = ({
  open,
  setOpen,
  handleSubmit,
  infoDetail,
  icon,
  buttonText = "Okay",
  buttonClass,
  buttonWidth = "120px",
}) => {
  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  const handleOkayClick = () => {
    setOpen(false);
    handleSubmit();
  };
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          style: {
            maxWidth: "520px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent className="!px-20 !py-[52px] relative">
          <DialogContentText id="alert-dialog-slide-description">
            <img
              src={CrossIcon}
              alt="crossIcon"
              className="ml-auto cursor-pointer absolute top-4 right-4"
              onClick={handleClose}
            />
            <div className="max-w-[360px] flex flex-col items-center font-hankengrotesk">
              {icon && (
                <div className="w-[88px] h-[88px] bg-[rgb(246,249,253)] flex justify-center items-center rounded-full">
                  <img src={icon} alt="icon" className="w-full h-full" />
                </div>
              )}
              <div className="w-full flex flex-col gap-3 mt-4">
                <div className="font-semibold text-[28px] leading-9 text-center text-[#353535]">
                  {infoDetail.heading}
                </div>
                {infoDetail?.innerContent && (
                  <div
                    className={`text-center ${
                      infoDetail?.innerContentClass
                        ? infoDetail?.innerContentClass
                        : ""
                    }`}
                  >
                    {infoDetail?.innerContent}
                  </div>
                )}
              </div>

              <button
                className={`btn h-12 flex justify-center items-center bg-custom-primary text-white font-medium py-[14px] text-center mt-8 rounded-lg w-full max-w-[${buttonWidth}] disabled:opacity-75 flex-1 px-[32px] font-hankengrotesk ${buttonClass}`}
                onClick={handleOkayClick}
              >
                {buttonText}
              </button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InfoDialog;
