import { forwardRef, useState } from "react";
import _ from "lodash";
import { Dialog, DialogContent, Slide } from "@mui/material";
import { CrossIcon } from "assets";
import { topUpPlans } from "utils/common";
import SubscriptionCard from "component/SubscriptionCard";
import { createCheckoutSession } from "services/Subscription";
import Loader from "component/Loader";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddCallsDialog = (props) => {
  const { open, setOpen } = props;

  const [loader, setLoader] = useState(false);

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  const gotoCheckout = async (type = "monthly1") => {
    setLoader(true);
    const response = await createCheckoutSession(type, true, false);
    const url = _.get(response, "data.url", "");
    if (url) {
      window.location.href = url;
    }
    setLoader(false);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          style: {
            maxWidth: "1208px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent className="!pt-[52px] !pb-[64px] !px-[80px] relative">
          {loader && (
            <div className="absolute top-0 left-0 right-0 bottom-0 backdrop-blur-sm content-center z-40">
              <Loader />
            </div>
          )}
          <img
            src={CrossIcon}
            alt="crossIcon"
            className="ml-auto cursor-pointer absolute top-4 right-4 z-50"
            onClick={handleClose}
          />
          <div className="flex flex-col justify-start items-start gap-11 max-w-[1208px] w-full mx-auto font-hankengrotesk">
            <div className="flex flex-col gap-7 w-full">
              <h6 className="font-semibold text-[28px] leading-9 text-[#353535] m-0">
                Add more calls
              </h6>
              <div className="w-full grid gap-5 lg:grid-cols-4 md:grid-cols-2 grid-cols-1">
                {topUpPlans?.map((plan) => {
                  return (
                    <SubscriptionCard
                      isFromDialog={true}
                      className="min-h-fit sm:min-h-fit pb-10 sm:pb-10"
                      plan={{ ...plan }}
                      onClick={() => gotoCheckout(plan.price)}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddCallsDialog;
