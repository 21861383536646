import AuthContext from "contexts/authContext";
import React, { useContext, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "routes";

function Router() {
  const { auth } = useContext(AuthContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  return <RouterProvider router={router(auth, isSidebarOpen, setIsSidebarOpen)} />;
}

export default Router;
