import React, { useEffect, useMemo, useState } from "react";
import get from "lodash/get";
import TemplatePromtExample from "./templatePromptExample";
import ChatListSidebar from "./chatListSidebar";
import { useNavigate, useParams } from "react-router-dom";
import { SendIcon } from "assets";
import {
  addChatMessage,
  createChat,
  getAllChat,
  getChatMessages,
} from "services/AIChat";
import { goToChatMessageEnd } from "utils/common";
import ChatMessages from "./ChatMessages";
import Loader from "component/Loader";

export default function AIChat() {
  const { jobId, chatId } = useParams();
  const navigate = useNavigate();

  const [chatList, setChatList] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatListLoader, setChatListLoader] = useState(false);
  const [chatMsgListLoader, setChatMsgListLoader] = useState(false);
  const [msgInput, setMsgInput] = useState("");
  const [msgSending, setMsgSending] = useState(false);
  const [lastChatId, setLastChatId] = useState(-1);

  useEffect(() => {
    getChats();
  }, []);

  useEffect(() => {
    if (chatId) getChatMessage();
  }, [chatId]);

  useEffect(() => {
    goToChatMessageEnd();
  }, [msgSending, chatMessages])
  
  const getChats = async () => {
    setChatListLoader(true);
    const chatList = await getAllChat(jobId);
    setChatList(get(chatList, "data", []));
    setChatListLoader(false);
  };

  const getChatMessage = async () => {
    setChatMsgListLoader(true);
    const chatmessageList = await getChatMessages(chatId);
    setChatMessages(get(chatmessageList, "data", []));

    if (get(chatmessageList, "data", []).length > 0) {
      setLastChatId(
        get(chatmessageList, "data", [])[
          get(chatmessageList, "data", []).length - 1
        ].id
      );
    }
    setChatMsgListLoader(false);
    goToChatMessageEnd();
  };

  const sendMessage = async (msg, type) => {
    if (chatId) {
      setMsgSending(true);
      setMsgInput(msg);
      goToChatMessageEnd();
      setTimeout(goToChatMessageEnd, 500);
      try {
        const msgData = await addChatMessage(msg, type, chatId);
        setChatMessages([...chatMessages, msgData.data[0], msgData.data[1]]);
        if (type === 1) {
          setMsgInput("");
        }
      } catch (err) {
        console.log("error while sending message");
      }
      goToChatMessageEnd();
      setMsgSending(false);
    } else {
      CreateNewChatAndSendMessage(msg, type);
    }
  };

  const CreateNewChatAndSendMessage = async (msg, type) => {
    setMsgSending(true);
    setMsgInput(msg);
    goToChatMessageEnd();
    const newChatData = await createChat(jobId, msg);

    setChatList([get(newChatData, "data.chat", {}), ...chatList]);
    if (newChatData?.data?.messages) {
      setChatMessages([
        ...chatMessages,
        newChatData.data.messages[0],
        newChatData.data.messages[1],
      ]);
    }

    if (type === 1) {
      setMsgInput("");
    }
    goToChatMessageEnd();
    navigate(`/${jobId}/ai-chat/${newChatData?.data?.chat?.id}`);
    setMsgSending(false);
  };

  const renderChatInput = useMemo(() => {
    return (
      <div className="w-full flex justify-center items-center xl:px-0 px-6">
        <div className="w-full xl:max-w-[678px] max-w-full  border border-[#E5E5E5] focus:border-[#353535] rounded-lg flex items-center py-[10px] pr-[21px] pl-[10px]">
          <textarea
            value={msgSending ? "" : msgInput}
            onChange={(e) => {
              if (!msgSending) setMsgInput(e.target.value);
            }}
            rows={1}
            placeholder="Ask me anything"
            className="input text-[#393939] bg-white w-full focus-visible:outline-none resize-none"
            onKeyDown={(e) => {
              if (e.shiftKey && e.keyCode === 13) {
                // setMsgInput(e.target.value + "\n");
              } else if (e.altKey && e.keyCode === 13) {
                setMsgInput(e.target.value + "\n");
              } else if (e.keyCode === 13) {
                if (chatId) {
                  if (msgInput.length > 0 && !msgSending)
                    sendMessage(msgInput, 1);
                } else {
                  CreateNewChatAndSendMessage(msgInput, 1);
                }
              }
            }}
          ></textarea>
          <div
            className={`${
              !msgInput ? "opacity-50" : ""
            } cursor-pointer btn bg-custom-primary h-[40px] flex items-center justify-center border-custom-primary border-[1px] border-purple font-medium text-[13px] leading-[16px] text-[#fff] p-2 rounded-[6px]`}
            onClick={() => {
              if (chatId) {
                if (msgInput.length > 0 && !msgSending)
                  sendMessage(msgInput, 1);
              } else {
                CreateNewChatAndSendMessage(msgInput, 1);
              }
            }}
          >
            <img src={SendIcon} alt="SendIcon" width={24} height={24} />
          </div>
        </div>
      </div>
    );
  }, [msgInput, msgSending]);

  const renderChatListSidebar = useMemo(() => {
    return (
      <ChatListSidebar
        chatId={chatId}
        jobId={jobId}
        chatList={chatList}
        chatListLoader={chatListLoader}
        setChatList={setChatList}
        setChatMessages={setChatMessages}
      />
    );
  }, [jobId, chatId, chatListLoader, chatList]);

  return (
    <div className="h-full max-h-[calc(100%_-_130px)] border-b-0 flex mt-3 border border-[#E5E5E5] rounded-ss-xl rounded-se-xl">
      {renderChatListSidebar}
      <div className="h-full w-full mx-auto flex flex-col py-6">
        <div className="h-full grid grid-cols-1 overflow-auto scrollbar">
          <div className={`w-full h-full max-w-[678px] mx-auto justify-center gap-[14px] ${chatMessages?.length === 0 && !msgSending ? "grid grid-cols-[repeat(auto-fill,minmax(332px,150px))] grid-rows-[80px] auto-rows-[80px]" : "flex"}`}>
            {chatMessages?.length === 0 && !msgSending && (
              <TemplatePromtExample setMsgInput={setMsgInput} />
            )}
            {(chatMessages.length > 0 || msgSending) && (
              <ChatMessages
                msgInput={msgInput}
                chatMessages={chatMessages}
                lastChatId={lastChatId}
                msgSending={msgSending}
                sendMessage={sendMessage}
              />
            )}
          </div>
            {chatMsgListLoader && <div className="w-full flex justify-center items-center mt-6"><Loader /></div>}
        </div>
        {renderChatInput}
      </div>
    </div>
  );
}
